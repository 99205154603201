import { call, put, select, takeLatest } from 'redux-saga/effects'
import { recordsActions } from './slice'
import { getRecordsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/helpers'
import { recordsSelectors } from './selectors'
import { IFilterRecords, IRecord } from './types'

function* getRecords(): any {
  yield put(recordsActions.setIsLoadingRecords(true))
  try {
    const filterRecoeds: IFilterRecords = yield select(
      recordsSelectors.filterRecords,
    )

    const response: AxiosResponse = yield call(getRecordsReq, filterRecoeds)

    const listOfNewData = (response?.data?.data?.records || []) as IRecord[]

    yield put(recordsActions.setRecords(listOfNewData))

    listOfNewData.length === 0 || listOfNewData.length < filterRecoeds.pageSize
      ? yield put(recordsActions.setIsAllRecordsFetched(true))
      : yield put(recordsActions.setIsAllRecordsFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(recordsActions.setIsLoadingRecords(false))
  }
}

export function* recordsSaga() {
  yield takeLatest(recordsActions.getRecords.type, getRecords)
}
