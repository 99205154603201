/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from 'store/toolkit'
import { SearchUserState } from './types'

// The initial state of the Overview container
export const initialState: SearchUserState = {
  coreId: '',
}

const searchUserSlice = createSlice({
  name: 'searchUser',
  initialState,
  reducers: {
    setCoreId(state, action: PayloadAction<string>) {
      state.coreId = action.payload
    },
  },
})

export const {
  actions: searchUserActions,
  reducer: searchUserReducer,
  name: sliceKey,
} = searchUserSlice
