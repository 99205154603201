import { useDispatch, useSelector } from 'react-redux'
import { FilterDocumentRejectionsContent } from './content'
import { FC } from 'react'
import { documentRejectionsSelectors } from 'app/containers/DocumentRejections/selectors'
import { documentRejectionsActions } from 'app/containers/DocumentRejections/slice'
import { Modal } from 'app/components/modal'

export const FilterDocumentRejectionsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    documentRejectionsSelectors.displayFilterDocumentRejectionsModal,
  )
  const handleModalClose = () => {
    dispatch(
      documentRejectionsActions.setDisplayFilterDocumentRejectionsModal(false),
    )
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterDocumentRejectionsContent />
    </Modal>
  )
}
