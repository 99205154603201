import { TableOfData } from './components/table'
import { Header } from './components/Header'
import { FilterPhoneVerificationsModal } from './components/modals/filter'

interface Props {}

export function PhoneVerifications(props: Props) {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterPhoneVerificationsModal />
    </>
  )
}
