import { Box, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { rolesActions } from '../../slice'
import { rolesSelectors } from '../../selectors'
import { TEXT_LG_SEMIBOLD } from 'styles/globalTypography'
import {
  COLUMN_ALIGN_END__JUSTIFY_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
} from 'styles/globalStyles'
import { useFormik } from 'formik'
import { mediaQueries } from 'styles/mediaQueries'
import BaseInput from 'app/components/input/baseInput'
import PrimaryButton from 'app/components/button/primary-button'
import { Modal } from 'app/components/modal'

export const AddRoleModal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(rolesSelectors.addRoleModalDisplay)

  const handleModalClose = () => {
    dispatch(rolesActions.setAddRoleModalDisplay(false))
  }

  const handleAddClick = () => {
    dispatch(rolesActions.addRole(values))
    handleModalClose()
  }

  const initialValues = {
    code: '',
    name: '',
  }

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues,
    onSubmit: handleAddClick,
    validateOnChange: false,
  })

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <StyledForm onSubmit={handleSubmit}>
        <Container>
          <TwoItemContainer>
            <RowContainer>
              <RowTitle>Code</RowTitle>
              <BaseInput
                value={values.code}
                fullWidth
                name="code"
                label="Code"
                onChange={handleChange}
              />
            </RowContainer>
            <RowContainer>
              <RowTitle>Name</RowTitle>
              <BaseInput
                type="text"
                value={values.name}
                fullWidth
                name="name"
                label="Name"
                onChange={handleChange}
              />
            </RowContainer>
          </TwoItemContainer>
          <PrimaryButtons type="submit">Add Role</PrimaryButtons>
        </Container>
      </StyledForm>
    </Modal>
  )
}

const StyledForm = styled('form')`
  ${ROW_ALIGN_CENTER__SPACE_B}
  gap:24px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  gap: 16px;
  `}
`
const Container = styled('div')`
  ${COLUMN_ALIGN_END__JUSTIFY_CENTER}
  gap: 42px;
  width: 100%;
  height: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 32px;
  `}
  ${mediaQueries.lessThan('xs')`
  gap: 24px;
  `}
`

const RowContainer = styled('div')`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
  gap: 16px;
`

const RowTitle = styled('label')`
  ${TEXT_LG_SEMIBOLD}
`
const TwoItemContainer = styled('div')`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
  gap: 24px;
  ${mediaQueries.lessThan('xs')` 
    flex-direction: column;
    gap: 16px;
  `}
`
const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
