/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { DocumentRejectionsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { documentRejectionsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

// The initial state of the Overview container
export const initialState: DocumentRejectionsState = {
  documentRejectionsList: [],
  isLoadingDocumentRejections: false,
  filterDocumentRejections: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfDocumentRejectionsTable: 0,
  isAllDocumentRejectionsFetched: false,
  displayFilterDocumentRejectionsModal: false,
}

const documentRejectionsSlice = createSlice({
  name: 'documentRejections',
  initialState,
  reducers: {
    getDocumentRejections(state) {},
    setDocumentRejections(
      state,
      action: PayloadAction<DocumentRejectionsState['documentRejectionsList']>,
    ) {
      state.documentRejectionsList = action.payload
    },
    setIsLoadingDocumentRejections(
      state,
      action: PayloadAction<
        DocumentRejectionsState['isLoadingDocumentRejections']
      >,
    ) {
      state.isLoadingDocumentRejections = action.payload
    },
    setFilterDocumentRejections(
      state,
      action: PayloadAction<
        DocumentRejectionsState['filterDocumentRejections']
      >,
    ) {
      state.filterDocumentRejections = action.payload
    },
    setLastPageOfDocumentRejectionsTable(state, action: PayloadAction<number>) {
      state.lastPageOfDocumentRejectionsTable = action.payload
    },
    setIsAllDocumentRejectionsFetched(state, action: PayloadAction<boolean>) {
      state.isAllDocumentRejectionsFetched = action.payload
    },
    setDisplayFilterDocumentRejectionsModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterDocumentRejectionsModal = action.payload
    },
  },
})

export const {
  actions: documentRejectionsActions,
  reducer: documentRejectionsReducer,
  name: sliceKey,
} = documentRejectionsSlice

export const useDocumentRejectionsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: documentRejectionsReducer })
  useInjectSaga({ key: sliceKey, saga: documentRejectionsSaga })
  return { documentRejectionsActions: documentRejectionsActions }
}
