/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { PhoneRejectionsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { phoneRejectionsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

// The initial state of the Overview container
export const initialState: PhoneRejectionsState = {
  phoneRejectionList: [],
  isLoadingPhoneRejections: false,
  filterPhoneRejections: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfPhoneRejectionsTable: 0,
  isAllPhoneRejectionsFetched: false,
  displayFilterPhoneRejectionsModal: false,
}

const phonerejectionsSlice = createSlice({
  name: 'phoneRejections',
  initialState,
  reducers: {
    getPhoneRejections(state) {},
    setPhoneRejections(
      state,
      action: PayloadAction<PhoneRejectionsState['phoneRejectionList']>,
    ) {
      state.phoneRejectionList = action.payload
    },
    setIsLoadingPhoneRejections(
      state,
      action: PayloadAction<PhoneRejectionsState['isLoadingPhoneRejections']>,
    ) {
      state.isLoadingPhoneRejections = action.payload
    },
    setFilterPhoneRejections(
      state,
      action: PayloadAction<PhoneRejectionsState['filterPhoneRejections']>,
    ) {
      state.filterPhoneRejections = action.payload
    },
    setLastPageOfPhoneRejectionsTable(state, action: PayloadAction<number>) {
      state.lastPageOfPhoneRejectionsTable = action.payload
    },
    setIsAllPhoneRejectionsFetched(state, action: PayloadAction<boolean>) {
      state.isAllPhoneRejectionsFetched = action.payload
    },
    setDisplayFilterPhoneRejectionsModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterPhoneRejectionsModal = action.payload
    },
  },
})

export const {
  actions: phoneRejectionsActions,
  reducer: phoneRejectionsReducer,
  name: sliceKey,
} = phonerejectionsSlice

export const usePhoneRejectionsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: phoneRejectionsReducer })
  useInjectSaga({ key: sliceKey, saga: phoneRejectionsSaga })
  return { phoneRejectionsActions: phoneRejectionsActions }
}
