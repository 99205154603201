import { useDispatch, useSelector } from 'react-redux'

import { FilterEmailRejectionsContent } from './content'
import { FC } from 'react'
import { emailRejectionsSelectors } from 'app/containers/EmailRejections/selectors'
import { emailRejectionsActions } from 'app/containers/EmailRejections/slice'
import { Modal } from 'app/components/modal'

export const FilterEmailRejectionsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    emailRejectionsSelectors.displayFilterEmailRejectionsModal,
  )
  const handleModalClose = () => {
    dispatch(emailRejectionsActions.setDisplayFilterEmailRejectionsModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterEmailRejectionsContent />
    </Modal>
  )
}
