import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FilterClientsContent } from './content'
import { clientsSelectors } from 'app/containers/Clients/selectors'
import { clientsActions } from 'app/containers/Clients/slice'
import { Modal } from 'app/components/modal'

interface IFilterModalProps {}

export const FilterClientsModal: FC<IFilterModalProps> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(clientsSelectors.displayFilterClientsModal)
  const handleModalClose = () => {
    dispatch(clientsActions.setDisplayFilterClientsModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterClientsContent />
    </Modal>
  )
}
