import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getLocalStorageOptions } from 'app/constants'

export const ORGANIZATIONS_TABLE_KEY = 'organizationsTable'

export const columnsForShowInOrganizationsTable: ColumnOfTable[] = [
  { id: 'name', label: 'Name' },
  { id: 'bypassPayment', label: 'Bypass Payment' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updateAt', label: 'Update At', isDate: true },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getLocalStorageOptions({ tableKey: ORGANIZATIONS_TABLE_KEY })
