import { useDispatch, useSelector } from 'react-redux'
import { FilterPhoneVerificationsContent } from './content'
import { FC } from 'react'
import { phoneVerificationsSelectors } from 'app/containers/PhoneVerifications/selectors'
import { phoneVerificationsActions } from 'app/containers/PhoneVerifications/slice'
import { Modal } from 'app/components/modal'

interface IFilterModalProps {}

export const FilterPhoneVerificationsModal: FC<IFilterModalProps> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    phoneVerificationsSelectors.displayFilterPhoneVerificationsModal,
  )
  const handleModalClose = () => {
    dispatch(
      phoneVerificationsActions.setDisplayFilterPhoneVerificationsModal(false),
    )
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterPhoneVerificationsContent />
    </Modal>
  )
}
