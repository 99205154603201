import { useDispatch, useSelector } from 'react-redux'
import { rolesActions } from './slice'
import { rolesSelectors } from './selectors'
import { useEffect } from 'react'
import { PageLoading } from 'app/components/common/loading/pageLoading'
import RoleCard from 'app/containers/Roles/components/card/role-card'
import { Role } from './types'
import { DeleteConfirmationModal } from './components/modals/confirmation'
import { AddRoleModal } from './components/modals/add-role'
import PrimaryButton from 'app/components/button/primary-button'
import { mediaQueries } from 'styles/mediaQueries'
import {
  COLUMN_JUSTIFY_START__ALIGN_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
} from 'styles/globalStyles'
import { styled } from '@mui/material'

interface Props {}

export function Roles(props: Props) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(rolesActions.fetchRoles({}))
    dispatch(rolesActions.fetchRoutes())
  }, [])

  const rolesList = useSelector(rolesSelectors.rolesList)
  const isLoadingRoles = useSelector(rolesSelectors.isLoadingRoles)

  const handleAddClick = () => {
    dispatch(rolesActions.setAddRoleModalDisplay(true))
  }

  return (
    <>
      <AddRoleModal />
      <DeleteConfirmationModal />
      <Wrapper>
        {isLoadingRoles ? (
          <PageLoading />
        ) : (
          <>
            <TitleWrapper>
              <TabTitle>Roles</TabTitle>
              <PrimaryButton onClick={handleAddClick}>
                Add New Role
              </PrimaryButton>
            </TitleWrapper>
            {rolesList.map((role: Role) => {
              return <RoleCard key={role.id} roleId={role.id} />
              // return <div key={role.ID}>{role.Name}</div>
            })}
          </>
        )}
      </Wrapper>
    </>
  )
}

export const Wrapper = styled('div')`
  ${COLUMN_JUSTIFY_START__ALIGN_CENTER}
  gap: 24px
`
export const TitleWrapper = styled('div')`
  width: 100%;
  ${ROW_ALIGN_CENTER__SPACE_B}
`

export const TabTitle = styled('h1')`
  ${mediaQueries.lessThan('md')`
    font-size: 28px;
    line-height: 32px;
    `}
  ${mediaQueries.lessThan('sm')`
    font-size: 24px;
    line-height: 28px;
    margin:10px 0;
    `}
`
