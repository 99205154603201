/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { SubmissionState } from './types'
import { createSlice } from 'store/toolkit'

// The initial state of the Overview container
export const initialState: SubmissionState = {
  isLoadingSubmissions: false,
  submitted: [],
  invalidated: [],
  isInvalidating: false,
}

const submissionsSlice = createSlice({
  name: 'submissions',
  initialState,
  reducers: {
    getSubmissionsByAddress(state, action: PayloadAction<string>) {},
    setIsLoadingSubmissions(state, action: PayloadAction<boolean>) {
      state.isLoadingSubmissions = action.payload
    },
    // FIXME Writable Draft Issue
    setSubmissions(state, action: PayloadAction<any[]>) {
      state.submitted = action.payload
    },
    setInvalidated(state, action: PayloadAction<any[]>) {
      state.invalidated = action.payload
    },
    setConfirmDeleteModalItem(state, action: PayloadAction<any>) {
      state.confirmDeleteModalItem = action.payload
    },
    invalidateTheSubmission(
      state,
      action: PayloadAction<{
        reason: string
        adminID: number
        voucherName: string
        userCoreID: string
        submissionID: string
      }>,
    ) {},
    fetchAllInvalidatedSubmissions() {},
    setIsInvalidating(state, action: PayloadAction<boolean>) {
      state.isInvalidating = action.payload
    },
  },
})

export const {
  actions: submissionsActions,
  reducer: submissionsReducer,
  name: sliceKey,
} = submissionsSlice
