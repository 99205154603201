import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { submissionsActions } from '../slice'
import { DocumentSubmitedTypes } from 'app/constants'
import { SubmissionsEndpoints } from 'api/endpoints/submissions'
import { removePrefix } from 'utils/string'

export function* invalidateTheSubmissionReq(
  action: ReturnType<typeof submissionsActions.invalidateTheSubmission>,
): any {
  const { reason, adminID, voucherName, userCoreID, submissionID } =
    action.payload
  const voucherNameLowerCased = voucherName.toLocaleLowerCase()
  const endPointName = voucherNameLowerCased.includes(
    DocumentSubmitedTypes.EMAIL.toLocaleLowerCase(),
  )
    ? SubmissionsEndpoints.invalidateEmail
    : voucherNameLowerCased.includes(
          DocumentSubmitedTypes.PHONE.toLocaleLowerCase(),
        )
      ? SubmissionsEndpoints.invalidatePhone
      : SubmissionsEndpoints.invalidateDocument

  const removedPrefixUserCoreID = removePrefix(userCoreID)

  const response: AxiosResponse = yield axiosInstance.post(endPointName, {
    reason,
    adminID,
    userCoreID: removedPrefixUserCoreID,
    submissionID,
    vouchererName: voucherName,
  })
  return response
}
