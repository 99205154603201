import { FC, useEffect } from 'react'
import { Box, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import BaseDropdownInput from 'app/components/input/dropdownInput'
import { mediaQueries } from 'styles/mediaQueries'
import { configurationsSelectors } from 'app/containers/Configurations/selectors'
import { configurationsActions } from 'app/containers/Configurations/slice'
import {
  COUNT_OPTIONS,
  FEE_OPTIONS,
  PERIOD_OPTIONS,
} from 'app/containers/Configurations/constants'
import PrimaryButton from 'app/components/button/primary-button'

interface IFilterContentProps {}

export const SetConfigurationContent: FC<IFilterContentProps> = () => {
  const dispatch = useDispatch()

  const newConfiguration = useSelector(configurationsSelectors.newConfiguration)
  const configurationsList = useSelector(
    configurationsSelectors.configurationsList,
  )

  useEffect(() => {
    if (configurationsList.length > 0) {
      const mappedConfigurations = configurationsList.map(config => ({
        key: config.key,
        value: Number(config.value),
      }))
      dispatch(
        configurationsActions.getValueOfNewConfiguration(mappedConfigurations),
      )
    }
  }, [dispatch])

  const handleInputChange = (event: any) => {
    const updatedConfiguration = newConfiguration.map(item => {
      if (item.key === event.target.name) {
        return {
          ...item,
          value: event.target.value,
        }
      }
      return item
    })

    dispatch(
      configurationsActions.getValueOfNewConfiguration(updatedConfiguration),
    )
  }

  const handleSetNewConfig = () => {
    dispatch(configurationsActions.setDisplaySetConfigurationModal(false))
    dispatch(configurationsActions.setNewConfiguration())
  }

  console.log('newConfiguration', newConfiguration)

  return (
    <Wrapper>
      <ItemsContainer>
        <ItemLabel>
          ASSET_MANAGEMENT_MAINTENANCE_FEE <Span>(Percentage)</Span>:
        </ItemLabel>
        <BaseDropdownInputs
          label="Percentage"
          name="ASSET_MANAGEMENT_MAINTENANCE_FEE"
          fullWidth
          options={FEE_OPTIONS}
          value={
            newConfiguration.find(
              item => item.key === 'ASSET_MANAGEMENT_MAINTENANCE_FEE',
            )?.value
          }
          onChange={handleInputChange}
        />
      </ItemsContainer>
      <ItemsContainer>
        <ItemLabel>
          ASSET_MANAGEMENT_ORDERS_COUNT <Span>(Number)</Span>:
        </ItemLabel>
        <BaseDropdownInputs
          label="Number"
          name="ASSET_MANAGEMENT_ORDERS_COUNT"
          fullWidth
          options={COUNT_OPTIONS}
          value={
            newConfiguration.find(
              item => item.key === 'ASSET_MANAGEMENT_ORDERS_COUNT',
            )?.value
          }
          onChange={handleInputChange}
        />
      </ItemsContainer>
      <ItemsContainer>
        <ItemLabel>
          ASSET_MANAGEMENT_PERIOD <Span>(Hour)</Span>:
        </ItemLabel>
        <BaseDropdownInputs
          label="Hour"
          name="ASSET_MANAGEMENT_PERIOD"
          fullWidth
          options={PERIOD_OPTIONS}
          value={
            newConfiguration.find(
              item => item.key === 'ASSET_MANAGEMENT_PERIOD',
            )?.value
          }
          onChange={handleInputChange}
        />
      </ItemsContainer>

      <CTAContainer>
        <PrimaryButtons onClick={handleSetNewConfig}>
          Set New Config.
        </PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const ItemsContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
  width: 100%;
  word-break: break-all;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`
const BaseDropdownInputs = styled(BaseDropdownInput)``
const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 240px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`

const Span = styled('span')`
  opacity: 0.5;
  font-size: 12px;
  word-break: keep-all;
`
