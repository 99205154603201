import { call, put, select, takeLatest } from 'redux-saga/effects'
import { phoneInvalidationsActions } from './slice'
import { getPhoneInvalidationsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/helpers'
import { phoneInvalidationsSelectors } from './selectors'
import { IFilterPhoneInvalidations, IPhoneInvalidation } from './types'

function* getPhoneInvalidations(): any {
  yield put(phoneInvalidationsActions.setIsLoadingPhoneInvalidations(true))
  try {
    const filterPhoneInvalidations: IFilterPhoneInvalidations = yield select(
      phoneInvalidationsSelectors.filterPhoneInvalidations,
    )
    const response: AxiosResponse = yield call(
      getPhoneInvalidationsReq,
      filterPhoneInvalidations,
    )

    const phoneInvalidationList = (response?.data?.data?.invalidations ||
      []) as IPhoneInvalidation[]

    yield put(
      phoneInvalidationsActions.setPhoneInvalidations(phoneInvalidationList),
    )

    phoneInvalidationList.length === 0 ||
    phoneInvalidationList.length < filterPhoneInvalidations.pageSize
      ? yield put(
          phoneInvalidationsActions.setIsAllPhoneInvalidationsFetched(true),
        )
      : yield put(
          phoneInvalidationsActions.setIsAllPhoneInvalidationsFetched(false),
        )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(phoneInvalidationsActions.setIsLoadingPhoneInvalidations(false))
  }
}

export function* phoneInvalidationsSaga() {
  yield takeLatest(
    phoneInvalidationsActions.getPhoneInvalidations.type,
    getPhoneInvalidations,
  )
}
