//FIXME: we must remove this folder and this is a templete

import { OrdersHeader } from './components/Header'
import { FilterBotOrdersModal } from './components/modals/filter'
import { BotOrdersTable } from './components/table'

interface Props {}

export function BotOrders(props: Props) {
  return (
    <>
      <OrdersHeader />
      <BotOrdersTable />
      <FilterBotOrdersModal />
    </>
  )
}
