import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getLocalStorageOptions } from 'app/constants'

export const CONFIGURATIONS_TABLE_KEY = 'configurationsTable'

export const columnsForShowInConfigurationsTable: ColumnOfTable[] = [
  {
    id: 'groupCode',
    label: 'Group Code',
  },
  {
    id: 'key',
    label: 'Key',
  },
  {
    id: 'value',
    label: 'Value',
  },
  {
    id: 'createdAt',
    label: 'Created At',
    isDate: true,
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    isDate: true,
  },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getLocalStorageOptions({ tableKey: CONFIGURATIONS_TABLE_KEY })

export const KEY_OPTIONS = [
  'ASSET_MANAGEMENT_PERIOD',
  'ASSET_MANAGEMENT_ORDERS_COUNT',
  'ASSET_MANAGEMENT_MAINTENANCE_FEE',
]

export const PERIOD_OPTIONS = [1, 2, 3, 4, 5]

export const COUNT_OPTIONS = [3, 4, 5, 6, 7, 8, 9, 10]

export const FEE_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
