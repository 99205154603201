import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { ConfigurationItem, IFilterConfigurations } from '../types'
import { ConfigurationsEndpoints } from 'api/endpoints/ping'
import { KEY_OPTIONS } from '../constants'
import { delay, put } from 'redux-saga/effects'
import { configurationsActions } from '../slice'

export function* getConfigurationsReq(filterData: IFilterConfigurations) {
  const response: AxiosResponse = yield axiosInstance.get(
    ConfigurationsEndpoints.configurationsList(filterData),
  )
  return response
}

export function* setNewConfigurationReq(data: ConfigurationItem[]) {
  data.map(item => {
    console.log()

    axiosInstance.post(ConfigurationsEndpoints.setNewConfiguration(), {
      key: item.key,
      value: item.value.toString(),
    })
  })
  yield delay(1000)

  yield put(configurationsActions.getConfigurations())
}
