import { Chip, styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { mediaQueries } from 'styles/mediaQueries'

export const Header = () => {
  const searchUserCoreId = useSelector(searchUserSelectors.coreId)

  return (
    <Wrapper>
      <TitleAndExportWrapper>
        <Title>Nfts</Title>
        <FilterSection>
          {searchUserCoreId ? (
            <StyledChip
              key={searchUserCoreId}
              label={`coreId: ${searchUserCoreId}`}
              variant="outlined"
            />
          ) : (
            <></>
          )}
        </FilterSection>
      </TitleAndExportWrapper>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: flex-end;
`

const Title = styled('div')`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: var(--black-knight);
  ${mediaQueries.lessThan('md')`
    font-size: 28px;
    line-height: 32px;
    `}
  ${mediaQueries.lessThan('sm')`
    font-size: 24px;
    line-height: 28px;
    `}
`
const TitleAndExportWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
    flex-direction: column;
    align-items: flex-start;
    `}
`

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`

const StyledChip = styled(Chip)`
  background: var(--alice-blue);
  border-color: var(--chip-color);
  color: var(--chip-color);
  font-weight: 700;
`
