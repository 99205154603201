import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationRequestHandler,
  AuthorizationResponse,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  RedirectRequestHandler,
  Requestor,
  StringMap,
  TokenRequest,
  TokenRequestHandler,
  TokenResponse,
} from '@ting/app-auth'
import history from 'router/history'
import { QueryStringUtils } from './QueryStringUtils'
import { AppPages } from 'app/constants'
import { LocalStorageKeys } from 'store/storage'

const OAUTH2_ADDRESS = process.env.REACT_APP_OAUTH2_ADDRESS
const OAUTH2_AUDIENCE = process.env.REACT_APP_OAUTH2_AUDIENCE
const OAUTH2_CLIENT_ID = process.env.REACT_APP_OAUTH2_CLIENT_ID
if (!OAUTH2_ADDRESS || !OAUTH2_AUDIENCE || !OAUTH2_CLIENT_ID) {
  console.error('OAUTH2_ADDRESS, OAUTH2_AUDIENCE, OAUTH2_CLIENT_ID not set')
  throw new Error('OAUTH2_ADDRESS, OAUTH2_AUDIENCE, OAUTH2_CLIENT_ID not set')
}

const settings = {
  authority: OAUTH2_ADDRESS,
  audience: OAUTH2_AUDIENCE,
  client_id: OAUTH2_CLIENT_ID,
  redirect_uri: `${window.location.origin}/login/callback`,
  response_type: 'code',
  scope: 'openid offline offline_access',
  extras: { prompt: 'consent', access_type: 'offline' },
}
class Authorizer {
  private notifier: AuthorizationNotifier
  private authorizationHandler: AuthorizationRequestHandler
  private tokenHandler: TokenRequestHandler

  // state
  private configuration: AuthorizationServiceConfiguration | undefined
  private request: AuthorizationRequest | undefined
  private code: string | undefined
  private requestor: Requestor
  private accessToken: string | undefined
  private refreshToken: string | undefined

  constructor() {
    this.notifier = new AuthorizationNotifier()
    this.authorizationHandler = new RedirectRequestHandler(
      undefined,
      new QueryStringUtils(),
    )
    this.requestor = new FetchRequestor()
    this.tokenHandler = new BaseTokenRequestHandler(this.requestor)
    // set notifier to deliver responses
    this.authorizationHandler.setAuthorizationNotifier(this.notifier)
    // set a listener to listen for authorization responses
    this.notifier.setAuthorizationListener((request, response) => {
      if (
        response &&
        response instanceof AuthorizationResponse &&
        request instanceof AuthorizationRequest
      ) {
        this.request = request
        this.code = response.code
      }
    })
    this.accessToken =
      localStorage.getItem(LocalStorageKeys.AUTH_ACCESS_TOKEN) ?? undefined
    this.refreshToken =
      localStorage.getItem(LocalStorageKeys.AUTH_REFRESH_TOKEN) ?? undefined
  }

  fetchServiceConfiguration() {
    return AuthorizationServiceConfiguration.fetchFromIssuer(
      settings.authority,
      this.requestor,
    )
      .then(response => {
        this.configuration = response
      })
      .catch(() => {
        // ignore
      })
  }

  async makeAuthorizationRequest() {
    const request = new AuthorizationRequest({
      audience: settings.audience,
      client_id: settings.client_id,
      redirect_uri: settings.redirect_uri,
      response_type: settings.response_type,
      scope: settings.scope,
      extras: settings.extras,
    })

    if (this.configuration) {
      this.authorizationHandler.performAuthorizationRequest(
        this.configuration,
        request,
      )
    } else {
      /** Try again till the configuration get ready */
      await this.fetchServiceConfiguration()
      setTimeout(() => this.makeAuthorizationRequest(), 1000)
    }
  }
  async makeTokenRequestFromCode() {
    if (!this.configuration || !this.code) {
      console.error('login_required')
      history.push(AppPages.Login)
    }

    let extras: StringMap | undefined
    if (this.request && this.request.internal) {
      extras = {}
      extras.code_verifier = this.request.internal.code_verifier
    }
    const request = new TokenRequest({
      client_id: settings.client_id,
      redirect_uri: settings.redirect_uri,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: this.code,
      refresh_token: undefined,
      extras,
    })

    try {
      if (this.configuration) {
        const tokenResponse = await this.tokenHandler.performTokenRequest(
          this.configuration,
          request,
        )
        return this.handleTokenResponse(tokenResponse)
      }
    } catch (error) {
      // do something
    }
  }

  async makeTokenRequestFromRefreshToken() {
    this.accessToken =
      localStorage.getItem(LocalStorageKeys.AUTH_ACCESS_TOKEN) ?? undefined
    this.refreshToken =
      localStorage.getItem(LocalStorageKeys.AUTH_REFRESH_TOKEN) ?? undefined

    if (!this.configuration || !this.refreshToken || !this.accessToken) {
      console.error('login_required')
      history.push(AppPages.Login)
    }

    let extras: StringMap | undefined
    if (this.request && this.request.internal) {
      extras = {}
      extras.code_verifier = this.request.internal.code_verifier
    }

    const request = new TokenRequest({
      client_id: settings.client_id,
      redirect_uri: window.location.href,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      code: this.accessToken,
      refresh_token: this.refreshToken,
      extras,
    })

    try {
      if (this.configuration) {
        const tokenResponse = await this.tokenHandler.performTokenRequest(
          this.configuration,
          request,
        )

        return this.handleTokenResponse(tokenResponse)
      }
    } catch (error) {
      // do something
    }
  }

  handleTokenResponse = (response: TokenResponse) => {
    return {
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
    }
    // unset code, so we can do refresh token exchanges subsequently
    // this.code = undefined;
  }

  checkForAuthorizationResponse() {
    return this.authorizationHandler.completeAuthorizationRequestIfPossible()
  }
  async signinCallback() {
    if (!this.configuration) {
      await this.fetchServiceConfiguration()
    }
    await this.checkForAuthorizationResponse()
    return this.makeTokenRequestFromCode()
  }
  async refreshTokenCallback() {
    if (!this.configuration) {
      await this.fetchServiceConfiguration()
    }
    return this.makeTokenRequestFromRefreshToken()
  }
}

export const authorizer = new Authorizer()
export default authorizer
