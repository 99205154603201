import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { emailVerificationsActions } from './slice'
import { getEmailVerificationsDataReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/helpers'
import { IEmailVerification, IFilterEmailVerifications } from './types'
import { emailVerificationsSelectors } from './selectors'

function* getEmailVerifications(): any {
  yield put(emailVerificationsActions.setIsLoadingEmailVerifications(true))

  try {
    const filterEmailVerifications: IFilterEmailVerifications = yield select(
      emailVerificationsSelectors.filterEmailVerifications,
    )

    const response: AxiosResponse = yield call(
      getEmailVerificationsDataReq,
      filterEmailVerifications,
    )

    const emailVerificationsList =
      (response?.data?.data?.emails as IEmailVerification[]) ?? []

    yield put(
      emailVerificationsActions.setEmailVerificationsList(
        emailVerificationsList,
      ),
    )
    emailVerificationsList.length === 0 ||
    emailVerificationsList.length < filterEmailVerifications.pageSize
      ? yield put(
          emailVerificationsActions.setIsAllEmailVerificationsFetched(true),
        )
      : yield put(
          emailVerificationsActions.setIsAllEmailVerificationsFetched(false),
        )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(emailVerificationsActions.setIsLoadingEmailVerifications(false))
  }
}

export function* emailVerificationsSaga() {
  yield takeLatest(
    emailVerificationsActions.getEmailVerifications.type,
    getEmailVerifications,
  )
}
