/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { PhoneInvalidationsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { phoneInvalidationsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

// The initial state of the Overview container
export const initialState: PhoneInvalidationsState = {
  phoneInvalidationList: [],
  isLoadingPhoneInvalidations: false,
  filterPhoneInvalidations: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfPhoneInvalidationsTable: 0,
  isAllPhoneInvalidationsFetched: false,
  displayFilterPhoneInvalidationsModal: false,
}

const phoneInvalidationsSlice = createSlice({
  name: 'phoneInvalidations',
  initialState,
  reducers: {
    getPhoneInvalidations(state) {},
    setPhoneInvalidations(
      state,
      action: PayloadAction<PhoneInvalidationsState['phoneInvalidationList']>,
    ) {
      state.phoneInvalidationList = action.payload
    },
    setIsLoadingPhoneInvalidations(
      state,
      action: PayloadAction<
        PhoneInvalidationsState['isLoadingPhoneInvalidations']
      >,
    ) {
      state.isLoadingPhoneInvalidations = action.payload
    },
    setFilterPhoneInvalidations(
      state,
      action: PayloadAction<
        PhoneInvalidationsState['filterPhoneInvalidations']
      >,
    ) {
      state.filterPhoneInvalidations = action.payload
    },
    setLastPageOfPhoneInvalidationsTable(state, action: PayloadAction<number>) {
      state.lastPageOfPhoneInvalidationsTable = action.payload
    },
    setIsAllPhoneInvalidationsFetched(state, action: PayloadAction<boolean>) {
      state.isAllPhoneInvalidationsFetched = action.payload
    },
    setDisplayFilterPhoneInvalidationsModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterPhoneInvalidationsModal = action.payload
    },
  },
})

export const {
  actions: phoneInvalidationsActions,
  reducer: phoneInvalidationsReducer,
  name: sliceKey,
} = phoneInvalidationsSlice

export const usePhoneInvalidationsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: phoneInvalidationsReducer })
  useInjectSaga({ key: sliceKey, saga: phoneInvalidationsSaga })
  return { phoneInvalidationsActions: phoneInvalidationsActions }
}
