export const isTrueBoolSetter = (input: string | boolean): boolean => {
  // if input is a boolean return it if it is a string check for "true",
  //if thats the case return boolean true if not return boolean false
  return typeof input === 'string' ? input === 'true' : input
}

export const successMessage = (response: any) => {
  return response?.data?.message || 'Process succeded'
}

export const errorMessage = (error: any) => {
  return (
    error?.error?.response?.data?.error?.message ||
    error?.response?.data?.error?.message ||
    error?.message ||
    'Process Failed'
  )
}

export const isNonEmptyTruthy = (input: any): boolean => {
  // Check for falsy values
  if (!input) {
    return false
  }

  // Check for an empty object
  if (
    typeof input === 'object' &&
    !Array.isArray(input) &&
    Object.keys(input).length === 0
  ) {
    return false
  }

  // Check for an empty array
  if (Array.isArray(input) && input.length === 0) {
    return false
  }

  // If none of the above, return true
  return true
}
