import { Box, styled } from '@mui/material'
import PrimaryButton from 'app/components/button/primary-button'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import BaseInput from 'app/components/input/baseInput'
import BaseDropdownInput from 'app/components/input/dropdownInput'
import { MaskInput } from 'app/components/input/inputWithMask'
import {
  STATUS_OF_PAYMENTS,
  TYPES_OF_PAYMENT_PROVIDER_CODE,
} from 'app/containers/Payments/constants'
import { paymentsSelectors } from 'app/containers/Payments/selectors'
import { paymentsActions } from 'app/containers/Payments/slice'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mediaQueries } from 'styles/mediaQueries'
import { getDateFromTimestamp, getTimestampFromDate } from 'utils/date'

interface IFilterPaymentsContentProps {}

export const FilterPaymentsContent: FC<IFilterPaymentsContentProps> = () => {
  const dispatch = useDispatch()
  const filterPayments = useSelector(paymentsSelectors.filterPayments)
  const [createAtFrom, setCreateAtFrom] = useState<string>(
    filterPayments?.createdAtFrom?.toString() ?? '',
  )
  const [createAtTo, setCreateAtTo] = useState<string>(
    filterPayments?.createdAtTo?.toString() ?? '',
  )
  const [updateAtFrom, setUpdateAtFrom] = useState<string>(
    filterPayments?.updateAtFrom?.toString() ?? '',
  )
  const [updateAtTo, setUpdateAtTo] = useState<string>(
    filterPayments?.updateAtTo?.toString() ?? '',
  )

  const handleDateInputChange = (event: any) => {
    const timeStamp = getTimestampFromDate(event.target.value)

    dispatch(
      paymentsActions.setFilterPayments({
        ...filterPayments,
        [event.target.name]: timeStamp,
      }),
    )
  }

  const handleInputChange = (event: any) => {
    dispatch(
      paymentsActions.setFilterPayments({
        ...filterPayments,
        [event.target.name]: event.target.value,
      }),
    )
  }

  useEffect(() => {
    setCreateAtFrom(getDateFromTimestamp(filterPayments?.createdAtFrom))
    setCreateAtTo(getDateFromTimestamp(filterPayments?.createdAtTo))
    setUpdateAtFrom(getDateFromTimestamp(filterPayments?.updateAtFrom))
    setUpdateAtTo(getDateFromTimestamp(filterPayments?.updateAtTo))
  }, [])

  const handleSearching = () => {
    dispatch(paymentsActions.setDisplayPaymentModal(false))
    dispatch(paymentsActions.getPayments())
  }

  return (
    <Wrapper>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Status:</ItemLabel>
          <BaseDropdownInput
            label="Status"
            placeholder="Status"
            name="status"
            fullWidth
            options={STATUS_OF_PAYMENTS}
            onChange={handleInputChange}
            value={filterPayments?.status ?? ''}
            endAdornment={
              filterPayments?.status ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      paymentsActions.setFilterPayments({
                        ...filterPayments,
                        status: '',
                      }),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>OrderID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="orderID"
            name="orderID"
            label="OrderID"
            onChange={handleInputChange}
            value={filterPayments?.orderID}
            InputProps={{
              endAdornment: filterPayments?.orderID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      paymentsActions.setFilterPayments({
                        ...filterPayments,
                        orderID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Payment Provider Code:</ItemLabel>
          <BaseDropdownInput
            fullWidth
            name="paymentProviderCode"
            label="Payment Provider Code"
            placeholder="Payment Provider Code"
            value={filterPayments?.paymentProviderCode ?? ''}
            options={TYPES_OF_PAYMENT_PROVIDER_CODE}
            onChange={handleInputChange}
            endAdornment={
              filterPayments?.paymentProviderCode ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      paymentsActions.setFilterPayments({
                        ...filterPayments,
                        paymentProviderCode: '',
                      }),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
        <ItemConatiner></ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CreatedAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtFrom}
            setMaskedValue={setCreateAtFrom}
            type="text"
            id="createdAtFrom"
            name="createdAtFrom"
            label="CreatedAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      paymentsActions.setFilterPayments({
                        ...filterPayments,
                        createdAtFrom: undefined,
                      }),
                    )
                    setCreateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>CreatedAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtTo}
            setMaskedValue={setCreateAtTo}
            type="text"
            id="createdAtTo"
            name="createdAtTo"
            label="CreatedAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      paymentsActions.setFilterPayments({
                        ...filterPayments,
                        createdAtTo: undefined,
                      }),
                    )
                    setCreateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>UpdateAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtFrom}
            setMaskedValue={setUpdateAtFrom}
            type="text"
            id="updateAtFrom"
            name="updateAtFrom"
            label="UpdateAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      paymentsActions.setFilterPayments({
                        ...filterPayments,
                        updateAtFrom: undefined,
                      }),
                    )
                    setUpdateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>UpdateAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtTo}
            setMaskedValue={setUpdateAtTo}
            type="text"
            id="updateAtTo"
            name="updateAtTo"
            label="UpdateAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      paymentsActions.setFilterPayments({
                        ...filterPayments,
                        updateAtTo: undefined,
                      }),
                    )
                    setUpdateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>

      <CTAContainer>
        <PrimaryButtons onClick={handleSearching}>Search</PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  min-width: 452px;
  ${mediaQueries.lessThan('lg')`
  min-width: 417px;
  `}
  ${mediaQueries.lessThan('md')`
  min-width: 239px;
  `}
  ${mediaQueries.lessThan('sm')`
  min-width: 241px;
  `}
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`

const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
