import { EmailInvalidationsHeader } from './components/Header'
import { FilterEmailInvalidationsModal } from './components/modals/filter'
import { EmailInvalidationsTable } from './components/table'

interface Props {}

export function EmailInvalidations(props: Props) {
  return (
    <>
      <EmailInvalidationsHeader />
      <EmailInvalidationsTable />
      <FilterEmailInvalidationsModal />
    </>
  )
}
