/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { EmailVerificationsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'
import { emailVerificationsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

// The initial state of the Overview container
export const initialState: EmailVerificationsState = {
  emailVerificationsList: [],
  isLoadingEmailVerifications: false,
  filterEmailVerifications: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfEmailVerificationsTable: 0,
  isAllEmailVerificationsFetched: false,
  displayFilterEmailVerificationsModal: false,
}

const testSlice = createSlice({
  name: 'emailVerifications',
  initialState,
  reducers: {
    getEmailVerifications(state) {},
    setEmailVerificationsList(
      state,
      action: PayloadAction<EmailVerificationsState['emailVerificationsList']>,
    ) {
      state.emailVerificationsList = action.payload
    },
    setIsLoadingEmailVerifications(
      state,
      action: PayloadAction<
        EmailVerificationsState['isLoadingEmailVerifications']
      >,
    ) {
      state.isLoadingEmailVerifications = action.payload
    },
    setFilterEmailVerifications(
      state,
      action: PayloadAction<
        EmailVerificationsState['filterEmailVerifications']
      >,
    ) {
      state.filterEmailVerifications = action.payload
    },
    setLastPageOfEmailVerificationsTable(state, action: PayloadAction<number>) {
      state.lastPageOfEmailVerificationsTable = action.payload
    },
    setIsAllEmailVerificationsFetched(state, action: PayloadAction<boolean>) {
      state.isAllEmailVerificationsFetched = action.payload
    },
    setDisplayFilterEmailVerificationsModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterEmailVerificationsModal = action.payload
    },
  },
})

export const {
  actions: emailVerificationsActions,
  reducer: emailVerificationsReducer,
  name: sliceKey,
} = testSlice

export const useEmaiVerificationsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: emailVerificationsReducer })
  useInjectSaga({ key: sliceKey, saga: emailVerificationsSaga })
  return { emailVerificationsActions: emailVerificationsActions }
}
