import { Box, styled } from '@mui/material'
import PrimaryButton from 'app/components/button/primary-button'
import { Modal } from 'app/components/modal'
import { AppPages } from 'app/constants'
import { loginActions } from 'app/containers/Login/slice'
import { useDispatch, useSelector } from 'react-redux'
import history from 'router/history'
import { globalActions } from 'store/slice'
import { RootState } from 'store/types'
import { COLUMN_CENTER } from 'styles/globalStyles'
import { H3_BOLD, TEXT_LG_SEMIBOLD } from 'styles/globalTypography'
import { mediaQueries } from 'styles/mediaQueries'

export const UnAuthorizedModal = () => {
  const dispatch = useDispatch()
  const titleOfModal = 'Expired/Invalid Token'
  const unAuthorized = useSelector(
    (state: RootState) => state.global?.unauthorized,
  )

  const handleLogOutClick = () => {
    dispatch(loginActions.logout())
    dispatch(globalActions.setUnauthorized(false))
    history.push(AppPages.Login)
  }
  return (
    <CModal open={unAuthorized ?? false}>
      <ModalContainer>
        <Title>{titleOfModal}</Title>
        <Body>Your Token is Expired/Invalid, Please Try to Login Again</Body>
        <StyledButton onClick={handleLogOutClick}>
          Go to Login Page
        </StyledButton>
      </ModalContainer>
    </CModal>
  )
}

const CModal = styled(Modal)`
  .MuiDialog-paperWidthSm {
    width: 608px;
    max-height: 921px;
    overflow: hidden;
  }

  .MuiDialog-paper {
    max-width: 608px;
    max-height: 921px;
    padding: 32px;
  }
`

const ModalContainer = styled(Box)`
  ${COLUMN_CENTER};
  gap: 40px;
  ${mediaQueries.lessThan('sm')`
  gap: 24px;
  `}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const Title = styled('p')`
  ${H3_BOLD}
  margin: 0;
  ${mediaQueries.lessThan('sm')`
  font-size: 20px;`}
`

const Body = styled('p')`
  ${TEXT_LG_SEMIBOLD}
  margin: 0;
  ${mediaQueries.lessThan('sm')`
  font-size: 12px;`}
`

const StyledButton = styled(PrimaryButton)`
  width: 200px;
  height: 50px;
  background-color: var(--mirage) !important;
  &:hover {
    opacity: 0.8;
  }
  ${mediaQueries.lessThan('sm')`
    width: 170px;
    height: 40px;
  `}
`
