import { useDispatch, useSelector } from 'react-redux'
import { FilterPaymentsContent } from './content'
import { FC } from 'react'
import { paymentsActions } from 'app/containers/Payments/slice'
import { paymentsSelectors } from 'app/containers/Payments/selectors'
import { Modal } from 'app/components/modal'

interface IFilterPaymentsModalProps {}

export const FilterPaymentsModal: FC<IFilterPaymentsModalProps> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(paymentsSelectors.displayPaymentModal)
  const handleModalClose = () => {
    dispatch(paymentsActions.setDisplayPaymentModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterPaymentsContent />
    </Modal>
  )
}
