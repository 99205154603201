import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { nftsActions } from '../slice'
import { DocumentSubmitedTypes } from 'app/constants'
import { removePrefix } from 'utils/string'
import { VoucherersEndpoints } from 'api/endpoints/voucherers'

export function* rejectNftReq(
  action: ReturnType<typeof nftsActions.rejectNft>,
): any {
  const { reason, voucherName, userCoreID, nftId } = action.payload
  const voucherNameLowerCased = voucherName.toLocaleLowerCase()
  const endPointName = voucherNameLowerCased.includes(
    DocumentSubmitedTypes.EMAIL.toLocaleLowerCase(),
  )
    ? VoucherersEndpoints.invalidateEmail
    : voucherNameLowerCased.includes(
          DocumentSubmitedTypes.PHONE.toLocaleLowerCase(),
        )
      ? VoucherersEndpoints.invalidatePhone
      : VoucherersEndpoints.invalidateDocument

  const removedPrefixUserCoreID = removePrefix(userCoreID)

  const response: AxiosResponse = yield axiosInstance.post(endPointName, {
    reason,
    userCoreID: removedPrefixUserCoreID,
    nftId,
    vouchererName: voucherName,
  })
  return response
}
