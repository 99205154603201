/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { IResubmissionItem, IResubmissionsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { resubmissionSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

// The initial state of the Overview container
export const initialState: IResubmissionsState = {
  resubmissionsList: [],
  isLoadingResubmissions: false,
  isAllResubmissionsFetched: false,
  lastPageOfResubmissionsTable: 0,
  filterResubmissions: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  displayFilterResubmissionsModal: false,
}

const resubmissionsSlice = createSlice({
  name: 'resubmissions',
  initialState,
  reducers: {
    getResubmissionsList(state) {},
    setIsLoadingResubmissions(state, action: PayloadAction<boolean>) {
      state.isLoadingResubmissions = action.payload
    },
    setResubmissions(state, action: PayloadAction<IResubmissionItem[]>) {
      state.resubmissionsList = action.payload
    },
    setIsAllResubmissionsFetched(state, action: PayloadAction<boolean>) {
      state.isAllResubmissionsFetched = action.payload
    },
    setLastPageOfResubmissionsTable(state, action: PayloadAction<number>) {
      state.lastPageOfResubmissionsTable = action.payload
    },
    setFilterResubmissions(
      state,
      action: PayloadAction<IResubmissionsState['filterResubmissions']>,
    ) {
      state.filterResubmissions = action.payload
    },
    setDisplayFilterResubmissionsModal(
      state,
      action: PayloadAction<
        IResubmissionsState['displayFilterResubmissionsModal']
      >,
    ) {
      state.displayFilterResubmissionsModal = action.payload
    },
  },
})

export const {
  actions: resubmissionsActions,
  reducer: resubmissionsReducer,
  name: sliceKey,
} = resubmissionsSlice

export const useResubmissionsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: resubmissionsReducer })
  useInjectSaga({ key: sliceKey, saga: resubmissionSaga })
  return { resubmissionsActions: resubmissionsActions }
}
