import { FC, useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import BaseDropdownInput from 'app/components/input/dropdownInput'

import BaseInput from 'app/components/input/baseInput'
import { MaskInput } from 'app/components/input/inputWithMask'
import { getDateFromTimestamp, getTimestampFromDate } from 'utils/date'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'

import { mediaQueries } from 'styles/mediaQueries'
import { assetsManagementSelectors } from 'app/containers/AssetsManagement/selectors'
import { assetsManagementActions } from 'app/containers/AssetsManagement/slice'
import { STATUS_OPTIONS } from 'app/containers/AssetsManagement/constants'
import PrimaryButton from 'app/components/button/primary-button'

interface IFilterContentProps {}

export const FilterAssetsManagementContent: FC<IFilterContentProps> = () => {
  const dispatch = useDispatch()
  const filterAssetsManagement = useSelector(
    assetsManagementSelectors.filterAssetsManagement,
  )

  const [createAtFrom, setCreateAtFrom] = useState<string>(
    filterAssetsManagement?.createdAtFrom?.toString() ?? '',
  )
  const [createAtTo, setCreateAtTo] = useState<string>(
    filterAssetsManagement?.createdAtTo?.toString() ?? '',
  )

  const handleInputChange = (event: any) => {
    dispatch(
      assetsManagementActions.setFilterAssetsManagement({
        ...filterAssetsManagement,
        [event.target.name]: event.target.value,
      }),
    )
  }

  const handleDateInputChange = (event: any) => {
    const timeStamp = getTimestampFromDate(event.target.value)

    dispatch(
      assetsManagementActions.setFilterAssetsManagement({
        ...filterAssetsManagement,
        [event.target.name]: timeStamp,
      }),
    )
  }

  useEffect(() => {
    setCreateAtFrom(getDateFromTimestamp(filterAssetsManagement?.createdAtFrom))
    setCreateAtTo(getDateFromTimestamp(filterAssetsManagement?.createdAtTo))
  }, [])

  const handleSearching = () => {
    dispatch(
      assetsManagementActions.setDisplayFilterAssetsManagementModal(false),
    )
    dispatch(assetsManagementActions.getAssetsManagement())
  }

  return (
    <Wrapper>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Asset:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="asset"
            name="asset"
            label="Asset"
            onChange={handleInputChange}
            value={filterAssetsManagement?.asset}
            InputProps={{
              endAdornment: filterAssetsManagement?.asset ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      assetsManagementActions.setFilterAssetsManagement({
                        ...filterAssetsManagement,
                        asset: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Status:</ItemLabel>
          <BaseDropdownInput
            label="Status"
            placeholder="Status"
            name="status"
            fullWidth
            options={STATUS_OPTIONS}
            onChange={handleInputChange}
            value={filterAssetsManagement?.status ?? ''}
            endAdornment={
              filterAssetsManagement?.status ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      assetsManagementActions.setFilterAssetsManagement({
                        ...filterAssetsManagement,
                        status: '',
                      }),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Fee:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="fee"
            name="fee"
            label="Fee"
            onChange={handleInputChange}
            value={filterAssetsManagement?.fee}
            InputProps={{
              endAdornment: filterAssetsManagement?.fee ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      assetsManagementActions.setFilterAssetsManagement({
                        ...filterAssetsManagement,
                        fee: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Price:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="price"
            name="price"
            label="Price"
            onChange={handleInputChange}
            value={filterAssetsManagement?.price}
            InputProps={{
              endAdornment: filterAssetsManagement?.price ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      assetsManagementActions.setFilterAssetsManagement({
                        ...filterAssetsManagement,
                        price: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CreatedAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtFrom}
            setMaskedValue={setCreateAtFrom}
            type="text"
            id="createdAtFrom"
            name="createdAtFrom"
            label="CreatedAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      assetsManagementActions.setFilterAssetsManagement({
                        ...filterAssetsManagement,
                        createdAtFrom: undefined,
                      }),
                    )
                    setCreateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>CreatedAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtTo}
            setMaskedValue={setCreateAtTo}
            type="text"
            id="createdAtTo"
            name="createdAtTo"
            label="CreatedAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      assetsManagementActions.setFilterAssetsManagement({
                        ...filterAssetsManagement,
                        createdAtTo: undefined,
                      }),
                    )
                    setCreateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <CTAContainer>
        <PrimaryButtons onClick={handleSearching}>Search</PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  ${mediaQueries.lessThan('md')`
  min-width: 239px;
  `}
  ${mediaQueries.lessThan('sm')`
  min-width: 241px;
  `}
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`

const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
