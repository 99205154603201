import { FC } from 'react'
import { styled, TextField, TextFieldProps } from '@mui/material'

const BaseInput: FC<TextFieldProps> = ({ ...props }) => {
  return (
    <>
      <BInput {...props} />
    </>
  )
}

export const BInput = styled(TextField)(() => ({
  height: 'auto',
  '& .MuiInputBase-root': {
    fontSize: '12px',
    paddingRight: '30px !important',
    backgroundColor: '#F5F7FB',
  },

  '& .MuiFormControl-root ': { height: 'auto' },
  '& .MuiTextField-root ': { height: 'auto' },
  '& .MuiChip-label': { fontSize: '12px' },
  '& input': {
    height: '2px',
    fontSize: '12px !important',
  },
  '& .MuiOutlinedInput-input': {},
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: `1px solid #017E84 !important`,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid #017E84 !important`,
  },
  '& label': {
    fontSize: '12px  !important',
    //   color: !disabled ? "#000000" : "#bcbcbc",
    top: '-7px',
  },
  '& .MuiInputLabel-shrink': {
    fontSize: '14px  !important',
    top: '0px',
  },
  '& legend': {
    fontSize: '11px',
  },
  '& label.Mui-focused': {
    fontSize: '14px  !important',
    color: '#000000',
    top: '0px',
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-20px',
    left: '0',
    height: ' 20px',
    fontSize: ' 12px',
  },
}))

export default BaseInput
