import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import BaseInput from 'app/components/input/baseInput'
import CustomTooltip from 'app/components/tooltip'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  COLUMN_ALIGN_START__JUSTIFY_START,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from 'styles/globalStyles'
import { addIsAddressMethodToYup } from 'utils/addCustomYupMethods'
import * as yup from 'yup'
import { searchUserActions } from '../slice'
import { searchUserSelectors } from '../selector'
import { mediaQueries } from 'styles/mediaQueries'
import PrimaryButton from 'app/components/button/primary-button'
import { styled } from '@mui/material'

addIsAddressMethodToYup()
export const SearchBar = () => {
  const dispatch = useDispatch()

  const searchUserCoreId = useSelector(searchUserSelectors.coreId)

  const initialValues = {
    coreId: searchUserCoreId || '',
  }

  const onSubmit = () => {
    dispatch(searchUserActions.setCoreId(values?.coreId))
  }

  const onDeleteClick = () => {
    setFieldValue('coreId', '')
    dispatch(searchUserActions.setCoreId(''))
  }

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    handleBlur,
  } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    initialValues,
    validationSchema: yup.object().shape({
      coreId: yup
        .string()
        .required('Address is required')
        // @ts-ignore
        .isAddress(),
    }),
    onSubmit,
  })

  return (
    <SearchBarContainer>
      <FormContainer onSubmit={handleSubmit}>
        <ItemLabel>CoreID:</ItemLabel>
        <ItemConatiner>
          <BaseInput
            fullWidth
            type="text"
            id="coreId"
            name="coreId"
            placeholder="Enter the CoreID"
            onChange={e => handleChange(e)}
            value={values.coreId}
            onBlur={handleBlur}
            InputProps={{
              endAdornment: values.coreId ? (
                <DeleteForeverIcon onClick={onDeleteClick} />
              ) : undefined,
            }}
          />
          <CustomTooltip message={errors?.coreId || ''}>
            <PrimaryButton type="submit" disabled={!!errors?.coreId}>
              Search
            </PrimaryButton>
          </CustomTooltip>
        </ItemConatiner>
      </FormContainer>
    </SearchBarContainer>
  )
}

const SearchBarContainer = styled('div')`
  width: 100%;
`

const ItemConatiner = styled('div')`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 16px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
 flex-direction: column;
 align-items: flex-start;
  gap: 8px;
  `}
`

const ItemLabel = styled('div')`
  font-size: 14px;
  font-weight: 500;
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`

const FormContainer = styled('form')`
  width: 100%;
  ${COLUMN_ALIGN_START__JUSTIFY_START}
  gap: 8px;
`
