import { DocumentSubmitedTypes } from 'app/constants'
import { getSha3FromString } from 'utils/sha3-for-sc'

// loop over DocumentSubmitedTypes object and hash the names
export const getAllRolesHashedName = () => {
  const hashedRoles: { [key: string]: string } = {}
  Object.values(DocumentSubmitedTypes).map(role => {
    const roleKey = `hashed${role}`
    hashedRoles[roleKey] = getSha3FromString(role)
  })
  return hashedRoles
}
