import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FilterApiKeysContent } from './content'
import { apiKeysSelectors } from 'app/containers/ApiKeys/selectors'
import { apiKeysActions } from 'app/containers/ApiKeys/slice'
import { Modal } from 'app/components/modal'

interface IFilterModalProps {}

export const FilterApiKeysModal: FC<IFilterModalProps> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(apiKeysSelectors.displayFilterApiKeysModal)
  const handleModalClose = () => {
    dispatch(apiKeysActions.setDisplayFilterApiKeysModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterApiKeysContent />
    </Modal>
  )
}
