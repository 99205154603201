import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getLocalStorageOptions } from 'app/constants'

export const EMAIL_INVALIDATIONS_TABLE_KEY = 'emailInvalidationsTable'

export const columnsForShowInEmailInvalidationsTable: ColumnOfTable[] = [
  { id: 'id', label: 'ID' },
  { id: 'adminID', label: 'AdminID' },
  { id: 'bctxID', label: 'BctxID' },
  { id: 'reason', label: 'Reason' },
  { id: 'status', label: 'Status' },
  { id: 'submissionID', label: 'SubmissionID' },
  { id: 'txHash', label: 'TxHash' },
  { id: 'userCoreID', label: 'UserCoreID' },
  { id: 'vouchererName', label: 'VouchererName' },
  { id: 'createdAt', label: 'CreatedAt', isDate: true },
  { id: 'updatedAt', label: 'UpdatedAt', isDate: true },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getLocalStorageOptions({ tableKey: EMAIL_INVALIDATIONS_TABLE_KEY })

export const EMAIL_INVALIDATIONS_STATUS_OPTIONS = [
  'SUCCESSFUL',
  'NEWLY_ADDED',
  'UNKNOWN_FAILED',
  'IN_PROGRESS',
  'FAILED',
]
