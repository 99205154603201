import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IssueVoucherContent } from './content'
import { vouchersSelectors } from 'app/containers/Vouchers/selectors'
import { vouchersActions } from 'app/containers/Vouchers/slice'
import { Modal } from 'app/components/modal'

interface IIssueVoucherModalProps {}

export const IssueVoucherModal: FC<IIssueVoucherModalProps> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(vouchersSelectors.displayVoucherModal)
  const handleModalClose = () => {
    dispatch(vouchersActions.setDisplayVoucherModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <IssueVoucherContent />
    </Modal>
  )
}
