import { BigNumber } from 'corebc'
import { isNonEmptyTruthy } from './helpers'
import { KYCNFT_STATE } from 'app/containers/Nfts/constants'
import { Nft, NftContractResponse } from 'app/containers/Nfts/types'

export const getAllNftResponseParser = (
  nfts: NftContractResponse[],
  nftsAddressesKeys: string[],
): Nft[] => {
  const formatedNfts: Nft[] = []
  nfts?.forEach((documentRelatedNft: NftContractResponse, index: number) => {
    if (isNonEmptyTruthy(documentRelatedNft[0])) {
      Object.values(documentRelatedNft[0]).forEach((value: bigint, idInex) => {
        const itemState = BigNumber.from(
          documentRelatedNft[1][idInex],
        ).toNumber()
        formatedNfts.push({
          id: BigNumber.from(value).toString(),
          state: KYCNFT_STATE[itemState as keyof typeof KYCNFT_STATE],
          type: nftsAddressesKeys[index],
        })
      })
    }
  })
  return formatedNfts
}
