/* --- STATE --- */
export interface LoginState {
  authCode: string
  tokenLimitEnd: null | number
  qrcode: string | null
  linkToPhone: string | null
  loginStatus: LoginStatus
  coreId: string
}
export interface JWTencodedData {
  at_hash: string
  aud: string[]
  auth_time: number
  exp: number
  iat: number
  iss: string
  jti: string
  rat: number
  sid: string
  sub: string
}
export enum LoginStatus {
  init,
  redirectLoading,
  loading,
  inProgress,
  success,
  error,
}
export type ContainerState = LoginState
