//FIXME: we must remove this folder and this is a templete

import { DocumentInvalidationsHeader } from './components/Header'
import { FilterDocumentInvalidationsModal } from './components/modals/filter'
import { DocumentInvalidationsTable } from './components/table'

interface Props {}

export function DocumentInvalidations(props: Props) {
  return (
    <>
      <DocumentInvalidationsHeader />
      <DocumentInvalidationsTable />
      <FilterDocumentInvalidationsModal />
    </>
  )
}
