import { PaymentsTable } from './components/PaymentsTable'
import { Header } from './components/Header'
import { FilterPaymentsModal } from './components/modals/filter'

interface Props {}

export function Payments(props: Props) {
  return (
    <>
      <Header />
      <PaymentsTable />
      <FilterPaymentsModal />
    </>
  )
}
