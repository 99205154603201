import { FC } from 'react'
import { styled } from '@mui/material'
import BaseButton, { BaseButtonProps } from './base-button'

interface PrimaryButtonProps extends BaseButtonProps {}

const PrimaryButton: FC<PrimaryButtonProps> = ({ children, ...props }) => {
  return <PrimaryButtonStyled {...props}>{children}</PrimaryButtonStyled>
}

const PrimaryButtonStyled = styled(BaseButton)<BaseButtonProps>`
  background-color: var(--denim);
  color: var(--white);
`

export default PrimaryButton
