import { EmailVerificationsTable } from './components/table'
import { EmailVerificationsHeader } from './components/Header'
import { FilterEmailVerificationsModal } from './components/modals/filter'

interface Props {}

export function EmailVerifications(props: Props) {
  return (
    <>
      <EmailVerificationsHeader />
      <EmailVerificationsTable />
      <FilterEmailVerificationsModal />
    </>
  )
}
