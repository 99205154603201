import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'

import SortableTable from 'app/components/table/sortableTable'
import { vouchersSelectors } from '../selectors'
import { vouchersActions } from '../slice'
import { VOUCHERS_TABLE_KEY, columnsForShowInVouchersTable } from '../constants'
import { capitalizeAllCharacters } from 'utils/string'
import { Voucher } from '../types'
import { adminsSelectors } from 'app/containers/Admins/selectors'
import { adminsActions } from 'app/containers/Admins/slice'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { useLocation } from 'react-router-dom'
import { AppPages, MAX_SAFE_INTEGER } from 'app/constants'
import { redirectToBlockBox } from 'utils/redirections'
import { ISortType } from 'app/types'

export const TableOfData = () => {
  const dispatch = useDispatch()

  const filterVouchers = useSelector(vouchersSelectors.filterVouchers)
  const vouchersList = useSelector(vouchersSelectors.vouchersList)
  const isLoadingVouchers = useSelector(vouchersSelectors.isLoadingVouchers)
  const lastPageOfVouchersTable = useSelector(
    vouchersSelectors.lastPageOfVouchersTable,
  )
  const isAllVouchersFetched = useSelector(
    vouchersSelectors.isAllVouchersFetched,
  )
  const admins = useSelector(adminsSelectors.adminList)

  const newVouchersListWithAdminName: (Voucher & { admin: string })[] =
    useMemo(() => {
      const adminMap = admins.reduce<{ [id: number]: string }>((map, admin) => {
        map[admin.id] = admin.fullName
        return map
      }, {})

      const newList = vouchersList.reduce(
        (newList, voucher) => {
          return [
            ...newList,
            {
              ...voucher,
              admin: adminMap[voucher.adminID],
            },
          ]
        },
        [] as (Voucher & { admin: string })[],
      )
      const uniqueArray = []
      const seenValues: Set<any> = new Set()

      for (const item of newList) {
        const value = item['id']
        if (!seenValues.has(value)) {
          seenValues.add(value)
          uniqueArray.push(item)
        }
      }

      return uniqueArray
    }, [admins, vouchersList])

  const searchUserCoreId = useSelector(searchUserSelectors.coreId)

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === AppPages.SearchUser) {
      // add coreId to filterData if the user is coming from search user page
      // and the search term (coreId) is not empty
      // if the user is coming from search user page and the search term (coreId) is empty
      // remove coreId filter and get all orders
      const searchUserFilters = {
        ...filterVouchers,
        userCoreID: searchUserCoreId ? searchUserCoreId : '',
      }
      dispatch(vouchersActions.setFilterVouchers(searchUserFilters))
    }
    dispatch(vouchersActions.getVouchersList())
    dispatch(adminsActions.fetchAdmins({}))
  }, [searchUserCoreId])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${VOUCHERS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      vouchersActions.setFilterVouchers({
        ...filterVouchers,
        pageSize: num,
      }),
    )
    dispatch(vouchersActions.getVouchersList())
  }

  return (
    <Wrapper>
      <SortableTable
        name={VOUCHERS_TABLE_KEY}
        isLoading={isLoadingVouchers}
        data={newVouchersListWithAdminName}
        columns={columnsForShowInVouchersTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            vouchersActions.setFilterVouchers({
              ...filterVouchers,
              page: newPage,
            }),
          )

          dispatch(vouchersActions.getVouchersList())
        }}
        setPage={page =>
          dispatch(vouchersActions.setLastPageOfVouchersTable(page))
        }
        page={lastPageOfVouchersTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          const propertyForSort =
            property.charAt(0).toLowerCase() + property.slice(1)

          dispatch(
            vouchersActions.setFilterVouchers({
              ...filterVouchers,
              sortField: propertyForSort,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = newVouchersListWithAdminName[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(vouchersActions.getVouchersList())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllVouchersFetched}
        onCellClick={(row, column) => {
          if (column === 'txHash') {
            redirectToBlockBox(row[column])
          }
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
