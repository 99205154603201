/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { AdminsState, Admin } from './types'
import { createSlice } from 'store/toolkit'
import { Role } from '../Roles/types'

// The initial state of the Overview container
export const initialState: AdminsState = {
  adminsList: [],
  isLoadingAdmins: false,
  deleteConfirmationModal: {
    display: false,
    admin: null,
    role: null,
  },
  isRoleAdding: null,
  blockConfirmationModal: {
    display: false,
    admin: null,
  },
  addAdminModal: false,
}

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    // create a action named fetch Roles without any payload
    fetchAdmins(state, action: PayloadAction<{ silent?: boolean }>) {},
    setAdminsList(state, action: PayloadAction<Admin[]>) {
      state.adminsList = action.payload
    },
    setIsLoadingAdmins(state, action: PayloadAction<boolean>) {
      state.isLoadingAdmins = action.payload
    },
    setDeleteConfirmationModalData(
      state,
      action: PayloadAction<AdminsState['deleteConfirmationModal']>,
    ) {
      state.deleteConfirmationModal = action.payload
    },
    deleteRoleFromAdmin(state) {},
    addRoleToAdmin(
      state,
      action: PayloadAction<{ role: Role; admin: Admin }>,
    ) {},
    setIsRoleAdding(state, action: PayloadAction<AdminsState['isRoleAdding']>) {
      state.isRoleAdding = action.payload
    },
    setBlockConfirmationModalData(
      state,
      action: PayloadAction<AdminsState['blockConfirmationModal']>,
    ) {
      state.blockConfirmationModal = action.payload
    },
    blockUnblockAdmin(state, action: PayloadAction<{ admin: Admin }>) {},
    setAddAdminModalDisplay(state, action: PayloadAction<boolean>) {
      state.addAdminModal = action.payload
    },
    addAdmin(
      state,
      action: PayloadAction<{ fullName: string; coreID: string }>,
    ) {},
    setRoleAddingIDOfAdmin(state, action: PayloadAction<number>) {
      state.roleAddingIDOfAdmin = action.payload
    },
  },
})

export const {
  actions: adminsActions,
  reducer: adminsReducer,
  name: sliceKey,
} = adminsSlice
