import { FC, useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { MaskInput } from 'app/components/input/inputWithMask'
import { getDateFromTimestamp, getTimestampFromDate } from 'utils/date'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import { mediaQueries } from 'styles/mediaQueries'
import { clientsActions } from 'app/containers/Clients/slice'
import { clientsSelectors } from 'app/containers/Clients/selectors'
import BaseInput from 'app/components/input/baseInput'
import PrimaryButton from 'app/components/button/primary-button'

interface IFilterContentProps {}

export const FilterClientsContent: FC<IFilterContentProps> = () => {
  const dispatch = useDispatch()
  const filterClients = useSelector(clientsSelectors.filterClients)

  const [createAtFrom, setCreateAtFrom] = useState<string>(
    filterClients?.createdAtFrom?.toString() ?? '',
  )
  const [createAtTo, setCreateAtTo] = useState<string>(
    filterClients?.createdAtTo?.toString() ?? '',
  )
  const [updateAtFrom, setUpdateAtFrom] = useState<string>(
    filterClients?.updateAtFrom?.toString() ?? '',
  )
  const [updateAtTo, setUpdateAtTo] = useState<string>(
    filterClients?.updateAtTo?.toString() ?? '',
  )

  const handleInputChange = (event: any) => {
    dispatch(
      clientsActions.setFilterClients({
        ...filterClients,
        [event.target.name]: event.target.value,
      }),
    )
  }

  const handleDateInputChange = (event: any) => {
    const timeStamp = getTimestampFromDate(event.target.value)

    dispatch(
      clientsActions.setFilterClients({
        ...filterClients,
        [event.target.name]: timeStamp,
      }),
    )
  }

  useEffect(() => {
    setCreateAtFrom(getDateFromTimestamp(filterClients?.createdAtFrom))
    setCreateAtTo(getDateFromTimestamp(filterClients?.createdAtTo))
    setUpdateAtFrom(getDateFromTimestamp(filterClients?.updateAtFrom))
    setUpdateAtTo(getDateFromTimestamp(filterClients?.updateAtTo))
  }, [])

  const handleSearching = () => {
    dispatch(clientsActions.setDisplayFilterClientsModal(false))
    dispatch(clientsActions.getClientsList())
  }

  return (
    <Wrapper>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CorePass ID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="corepassID"
            name="corepassID"
            label="CorePass ID"
            onChange={handleInputChange}
            value={filterClients?.corepassID}
            InputProps={{
              endAdornment: filterClients?.corepassID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      clientsActions.setFilterClients({
                        ...filterClients,
                        corepassID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Name:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="name"
            name="name"
            label="Name"
            onChange={handleInputChange}
            value={filterClients?.name}
            InputProps={{
              endAdornment: filterClients?.name ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      clientsActions.setFilterClients({
                        ...filterClients,
                        name: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Phone:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="phone"
            name="phone"
            label="Phone"
            onChange={handleInputChange}
            value={filterClients?.phone}
            InputProps={{
              endAdornment: filterClients?.phone ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      clientsActions.setFilterClients({
                        ...filterClients,
                        phone: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Email:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="email"
            name="email"
            label="Email"
            onChange={handleInputChange}
            value={filterClients?.email}
            InputProps={{
              endAdornment: filterClients?.email ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      clientsActions.setFilterClients({
                        ...filterClients,
                        email: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CreatedAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtFrom}
            setMaskedValue={setCreateAtFrom}
            type="text"
            id="createdAtFrom"
            name="createdAtFrom"
            label="CreatedAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      clientsActions.setFilterClients({
                        ...filterClients,
                        createdAtFrom: undefined,
                      }),
                    )
                    setCreateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>CreatedAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtTo}
            setMaskedValue={setCreateAtTo}
            type="text"
            id="createdAtTo"
            name="createdAtTo"
            label="CreatedAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      clientsActions.setFilterClients({
                        ...filterClients,
                        createdAtTo: undefined,
                      }),
                    )
                    setCreateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>UpdateAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtFrom}
            setMaskedValue={setUpdateAtFrom}
            type="text"
            id="updateAtFrom"
            name="updateAtFrom"
            label="UpdateAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      clientsActions.setFilterClients({
                        ...filterClients,
                        updateAtFrom: undefined,
                      }),
                    )
                    setUpdateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>UpdateAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtTo}
            setMaskedValue={setUpdateAtTo}
            type="text"
            id="updateAtTo"
            name="updateAtTo"
            label="UpdateAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      clientsActions.setFilterClients({
                        ...filterClients,
                        updateAtTo: undefined,
                      }),
                    )
                    setUpdateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <CTAContainer>
        <PrimaryButtons onClick={handleSearching}>Search</PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`
const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
