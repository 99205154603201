import { SearchBar } from './components/search-bar'
import { TabContainer } from './components/tab-container'

interface Props {}

export function SearchUser(props: Props) {
  return (
    <>
      <SearchBar />
      <TabContainer />
    </>
  )
}
