/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { ClientSourceKeysState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { clientSourceKeysSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

// The initial state of the Overview container
export const initialState: ClientSourceKeysState = {
  clientSourceKeysList: [],
  isLoadingClientSourceKeys: false,
  filterClientSourceKeys: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfClientSourceKeysTable: 0,
  isAllClientSourceKeysFetched: false,
  displayFilterClientSourceKeysModal: false,
}

const clientSourceKeysSlice = createSlice({
  name: 'clientSourceKeys',
  initialState,
  reducers: {
    getClientSourceKeysList(state) {},
    setClientSourceKeys(
      state,
      action: PayloadAction<ClientSourceKeysState['clientSourceKeysList']>,
    ) {
      state.clientSourceKeysList = action.payload
    },
    setIsLoadingClientSourceKeys(
      state,
      action: PayloadAction<ClientSourceKeysState['isLoadingClientSourceKeys']>,
    ) {
      state.isLoadingClientSourceKeys = action.payload
    },
    setFilterClientSourceKeys(
      state,
      action: PayloadAction<ClientSourceKeysState['filterClientSourceKeys']>,
    ) {
      state.filterClientSourceKeys = action.payload
    },
    setLastPageOfClientSourceKeysTable(state, action: PayloadAction<number>) {
      state.lastPageOfClientSourceKeysTable = action.payload
    },
    setIsAllClientSourceKeysFetched(state, action: PayloadAction<boolean>) {
      state.isAllClientSourceKeysFetched = action.payload
    },
    setDisplayFilterClientSourceKeysModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterClientSourceKeysModal = action.payload
    },
  },
})

export const {
  actions: clientSourceKeysActions,
  reducer: clientSourceKeysReducer,
  name: sliceKey,
} = clientSourceKeysSlice

export const useClientSourceKeysSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: clientSourceKeysReducer })
  useInjectSaga({ key: sliceKey, saga: clientSourceKeysSaga })
  return { clientSourceKeysActions: clientSourceKeysActions }
}
