import { FC, useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import BaseDropdownInput from 'app/components/input/dropdownInput'
import {
  ORDER_OF_SORT_IN_SUMSUB_STATUS,
  ORDER_OF_SORT_IN_USER_STATUS,
} from 'app/containers/Resubmissions/constants'
import BaseInput from 'app/components/input/baseInput'
import { MaskInput } from 'app/components/input/inputWithMask'
import { allResubmissionsSelectors } from 'app/containers/Resubmissions/selectors'
import { resubmissionsActions } from 'app/containers/Resubmissions/slice'
import { getDateFromTimestamp, getTimestampFromDate } from 'utils/date'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import PrimaryButton from 'app/components/button/primary-button'
import { mediaQueries } from 'styles/mediaQueries'

interface IFilterContentProps {}

export const FilterResubmissionsContent: FC<IFilterContentProps> = () => {
  const dispatch = useDispatch()
  const filterResubmissions = useSelector(
    allResubmissionsSelectors.filterResubmissions,
  )

  const [createAtFrom, setCreateAtFrom] = useState<string>(
    filterResubmissions?.createdAtFrom?.toString() ?? '',
  )
  const [createAtTo, setCreateAtTo] = useState<string>(
    filterResubmissions?.createdAtTo?.toString() ?? '',
  )
  const [updateAtFrom, setUpdateAtFrom] = useState<string>(
    filterResubmissions?.updateAtFrom?.toString() ?? '',
  )
  const [updateAtTo, setUpdateAtTo] = useState<string>(
    filterResubmissions?.updateAtTo?.toString() ?? '',
  )

  const handleInputChange = (event: any) => {
    dispatch(
      resubmissionsActions.setFilterResubmissions({
        ...filterResubmissions,
        [event.target.name]: event.target.value,
      }),
    )
  }

  const handleDateInputChange = (event: any) => {
    const timeStamp = getTimestampFromDate(event.target.value)

    dispatch(
      resubmissionsActions.setFilterResubmissions({
        ...filterResubmissions,
        [event.target.name]: timeStamp,
      }),
    )
  }

  useEffect(() => {
    setCreateAtFrom(getDateFromTimestamp(filterResubmissions?.createdAtFrom))
    setCreateAtTo(getDateFromTimestamp(filterResubmissions?.createdAtTo))
    setUpdateAtFrom(getDateFromTimestamp(filterResubmissions?.updateAtFrom))
    setUpdateAtTo(getDateFromTimestamp(filterResubmissions?.updateAtTo))
  }, [])

  const handleSearching = () => {
    dispatch(resubmissionsActions.setDisplayFilterResubmissionsModal(false))
    dispatch(resubmissionsActions.getResubmissionsList())
  }

  return (
    <Wrapper>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>User Status:</ItemLabel>
          <BaseDropdownInput
            label="User Status"
            placeholder="User Status"
            name="userStatus"
            fullWidth
            options={ORDER_OF_SORT_IN_USER_STATUS}
            onChange={handleInputChange}
            value={filterResubmissions?.userStatus ?? ''}
            endAdornment={
              filterResubmissions?.userStatus ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      resubmissionsActions.setFilterResubmissions({
                        ...filterResubmissions,
                        userStatus: '',
                      }),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Provider Status:</ItemLabel>
          <BaseDropdownInput
            label="Provider Status"
            placeholder="Provider Status"
            name="providerStatus"
            fullWidth
            options={ORDER_OF_SORT_IN_SUMSUB_STATUS}
            onChange={handleInputChange}
            value={filterResubmissions?.providerStatus ?? ''}
            endAdornment={
              filterResubmissions?.providerStatus ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      resubmissionsActions.setFilterResubmissions({
                        ...filterResubmissions,
                        providerStatus: '',
                      }),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CoreID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="coreId"
            name="coreID"
            label="CoreID"
            onChange={handleInputChange}
            value={filterResubmissions?.coreID}
            InputProps={{
              endAdornment: filterResubmissions?.coreID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      resubmissionsActions.setFilterResubmissions({
                        ...filterResubmissions,
                        coreID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Denied Count:</ItemLabel>
          <BaseInput
            fullWidth
            type="number"
            id="deniedCount"
            name="deniedCount"
            label="Denied Count"
            onChange={handleInputChange}
            value={filterResubmissions?.deniedCount ?? ''}
            InputProps={{
              endAdornment: filterResubmissions?.deniedCount ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      resubmissionsActions.setFilterResubmissions({
                        ...filterResubmissions,
                        deniedCount: undefined,
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>

      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Voucherer Name:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="vouchererName"
            name="vouchererName"
            label="Voucherer Name"
            onChange={handleInputChange}
            value={filterResubmissions?.vouchererName}
            InputProps={{
              endAdornment: filterResubmissions?.vouchererName ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      resubmissionsActions.setFilterResubmissions({
                        ...filterResubmissions,
                        vouchererName: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner></ItemConatiner>
      </TwoItemsContainer>

      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CreatedAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtFrom}
            setMaskedValue={setCreateAtFrom}
            type="text"
            id="createdAtFrom"
            name="createdAtFrom"
            label="CreatedAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      resubmissionsActions.setFilterResubmissions({
                        ...filterResubmissions,
                        createdAtFrom: undefined,
                      }),
                    )
                    setCreateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>CreatedAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtTo}
            setMaskedValue={setCreateAtTo}
            type="text"
            id="createdAtTo"
            name="createdAtTo"
            label="CreatedAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      resubmissionsActions.setFilterResubmissions({
                        ...filterResubmissions,
                        createdAtTo: undefined,
                      }),
                    )
                    setCreateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>UpdateAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtFrom}
            setMaskedValue={setUpdateAtFrom}
            type="text"
            id="updateAtFrom"
            name="updateAtFrom"
            label="UpdateAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      resubmissionsActions.setFilterResubmissions({
                        ...filterResubmissions,
                        updateAtFrom: undefined,
                      }),
                    )
                    setUpdateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>UpdateAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtTo}
            setMaskedValue={setUpdateAtTo}
            type="text"
            id="updateAtTo"
            name="updateAtTo"
            label="UpdateAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      resubmissionsActions.setFilterResubmissions({
                        ...filterResubmissions,
                        updateAtTo: undefined,
                      }),
                    )
                    setUpdateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <CTAContainer>
        <PrimaryButtons onClick={handleSearching}>Search</PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`
const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  min-width: 452px;
  ${mediaQueries.lessThan('lg')`
  min-width: 417px;
  `}
  ${mediaQueries.lessThan('md')`
  min-width: 239px;
  `}
  ${mediaQueries.lessThan('sm')`
  min-width: 241px;
  `}
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`

const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
