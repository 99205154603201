/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { RejectNftPayload, NftsState } from './types'
import { createSlice } from 'store/toolkit'

// The initial state of the Overview container
export const initialState: NftsState = {
  isLoading: false,
  Nfts: [],
  coreId: '',
  isRejectingNft: false,
}

const nftsSlice = createSlice({
  name: 'nfts',
  initialState,
  reducers: {
    fetchNfts(state, action: PayloadAction<string>) {},
    setIsLoading(state, action: PayloadAction<NftsState['isLoading']>) {
      state.isLoading = action.payload
    },
    setNfts(state, action: PayloadAction<NftsState['Nfts']>) {
      state.Nfts = action.payload
    },
    setConfirmRejectModalItem(
      state,
      action: PayloadAction<NftsState['confirmRejectModalItem']>,
    ) {
      state.confirmRejectModalItem = action.payload
    },
    rejectNft(state, action: PayloadAction<RejectNftPayload>) {},
    fetchAllInvalidatedSubmissions() {},
    setCoreId(state, action: PayloadAction<NftsState['coreId']>) {
      state.coreId = action.payload
    },
    setIsRejectingNft(
      state,
      action: PayloadAction<NftsState['isRejectingNft']>,
    ) {
      state.isRejectingNft = action.payload
    },
  },
})

export const {
  actions: nftsActions,
  reducer: nftsReducer,
  name: sliceKey,
} = nftsSlice
