import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getLocalStorageOptions } from 'app/constants'

export const DOMAINS_TABLE_KEY = 'domainsTable'

export const columnsForShowInDomainsTable: ColumnOfTable[] = [
  { id: 'name', label: 'Name' },
  { id: 'domain', label: 'Domain' },
  { id: 'framework', label: 'Framework' },
  { id: 'organizationID', label: 'OrganizationID' },
  { id: 'organizationUuid', label: 'Organization UUID' },
  { id: 'platform', label: 'Platform' },
  { id: 'status', label: 'Status' },
  { id: 'verifyCode', label: 'Verify Code' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updatedAt', label: 'Updated At', isDate: true },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getLocalStorageOptions({ tableKey: DOMAINS_TABLE_KEY })

export const FRAMEWORK_OPTIONS = [
  'ReactJS',
  'VueJS',
  'Angular',
  'Flutter',
  'ReactNative',
]

export const PLATFORM_OPTIONS = ['WEB', 'MOBILE']

export const STATUS_OPTIONS = ['VERIFIED', 'UNVERIFIED']
