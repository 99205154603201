import { Header } from './components/Header'
import { ConfirmDeleteModal } from './components/modals/confirmDeleteModal'
import { TableOfData } from './components/table'

interface Props {}

export function Submissions(props: Props) {
  return (
    <>
      <Header />
      <TableOfData />
      <ConfirmDeleteModal />
    </>
  )
}
