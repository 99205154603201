/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { OrdersState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { ordersSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

// The initial state of the Overview container
export const initialState: OrdersState = {
  orderList: [],
  isLoadingOrders: false,
  filterOrders: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfOrdersTable: 0,
  isAllOrdersFetched: false,
  displayFilterOrdersModal: false,
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    getOrders(state) {},
    setOrders(state, action: PayloadAction<OrdersState['orderList']>) {
      state.orderList = action.payload
    },
    setIsLoadingOrders(
      state,
      action: PayloadAction<OrdersState['isLoadingOrders']>,
    ) {
      state.isLoadingOrders = action.payload
    },
    setFilterOrders(state, action: PayloadAction<OrdersState['filterOrders']>) {
      state.filterOrders = action.payload
    },
    setLastPageOfOrdersTable(state, action: PayloadAction<number>) {
      state.lastPageOfOrdersTable = action.payload
    },
    setIsAllOrdersFetched(state, action: PayloadAction<boolean>) {
      state.isAllOrdersFetched = action.payload
    },
    setDisplayFilterOrdersModal(state, action: PayloadAction<boolean>) {
      state.displayFilterOrdersModal = action.payload
    },
  },
})

export const {
  actions: ordersActions,
  reducer: ordersReducer,
  name: sliceKey,
} = ordersSlice

export const useOrdersSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: ordersReducer })
  useInjectSaga({ key: sliceKey, saga: ordersSaga })
  return { ordersActions: ordersActions }
}
