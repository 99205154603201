import { ordersActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ordersSelectors } from '../selectors'
import { styled } from '@mui/material'
import { ORDERS_TABLE_KEY, columnsForShowInOrdersTable } from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { useLocation } from 'react-router-dom'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { AppPages, MAX_SAFE_INTEGER } from 'app/constants'
import { redirectToBlockBox } from 'utils/redirections'
import { ISortType } from 'app/types'

export const OrdersTable = () => {
  const dispatch = useDispatch()

  const orders = useSelector(ordersSelectors.orderList)
  const isLoadingOrders = useSelector(ordersSelectors.isLoadingOrders)
  const filterOrders = useSelector(ordersSelectors.filterOrders)
  const lastPageOfOrdersTable = useSelector(
    ordersSelectors.lastPageOfOrdersTable,
  )
  const isAllOrdersFetched = useSelector(ordersSelectors.isAllOrdersFetched)
  const searchUserCoreId = useSelector(searchUserSelectors.coreId)

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === AppPages.SearchUser) {
      // add coreId to filterData if the user is coming from search user page
      // and the search term (coreId) is not empty
      // if the user is coming from search user page and the search term (coreId) is empty
      // remove coreId filter and get all orders
      const searchUserFilters = {
        ...filterOrders,
        coreID: searchUserCoreId ? searchUserCoreId : '',
      }
      dispatch(ordersActions.setFilterOrders(searchUserFilters))
    }
    dispatch(ordersActions.getOrders())
  }, [searchUserCoreId])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${ORDERS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(ordersActions.setFilterOrders({ ...filterOrders, pageSize: num }))
    dispatch(ordersActions.getOrders())
  }
  return (
    <Wrapper>
      <SortableTable
        name={ORDERS_TABLE_KEY}
        isLoading={isLoadingOrders}
        data={orders}
        columns={columnsForShowInOrdersTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            ordersActions.setFilterOrders({
              ...filterOrders,
              page: newPage,
            }),
          )

          dispatch(ordersActions.getOrders())
        }}
        setPage={page => dispatch(ordersActions.setLastPageOfOrdersTable(page))}
        page={lastPageOfOrdersTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            ordersActions.setFilterOrders({
              ...filterOrders,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = orders[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(ordersActions.getOrders())
            }
          }
        }}
        countOfData={MAX_SAFE_INTEGER}
        showNumberOfPages={false}
        disableNextButton={isAllOrdersFetched}
        onCellClick={(row, column) => {
          if (column === 'txHash') {
            redirectToBlockBox(row[column])
          }
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
