import { useDispatch, useSelector } from 'react-redux'
import { FilterPhoneRejectionsContent } from './content'
import { FC } from 'react'
import { phoneRejectionsSelectors } from 'app/containers/PhoneRejections/selectors'
import { phoneRejectionsActions } from 'app/containers/PhoneRejections/slice'
import { Modal } from 'app/components/modal'

interface IFilterPhoneRejectionsModalProps {}

export const FilterPhoneRejectionsModal: FC<
  IFilterPhoneRejectionsModalProps
> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    phoneRejectionsSelectors.displayFilterPhoneRejectionsModal,
  )
  const handleModalClose = () => {
    dispatch(phoneRejectionsActions.setDisplayFilterPhoneRejectionsModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterPhoneRejectionsContent />
    </Modal>
  )
}
