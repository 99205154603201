/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { ConfigurationsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { configurationsSaga } from './saga'
import {
  KEY_OPTIONS,
  localPageSize,
  localSortField,
  localSortType,
} from './constants'

// The initial state of the Overview container
export const initialState: ConfigurationsState = {
  configurationsList: [],
  isLoadingConfigurations: false,
  filterConfigurations: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfConfigurationsTable: 0,
  isAllConfigurationsFetched: false,
  displayFilterConfigurationsModal: false,
  displaySetConfigurationModal: false,
  newConfiguration: [
    {
      key: KEY_OPTIONS[0],
      value: 1,
    },
    {
      key: KEY_OPTIONS[1],
      value: 3,
    },
    {
      key: KEY_OPTIONS[2],
      value: 1,
    },
  ],
}

const configurationsSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    getConfigurations(state) {},
    setConfigurations(
      state,
      action: PayloadAction<ConfigurationsState['configurationsList']>,
    ) {
      state.configurationsList = action.payload
    },
    setIsLoadingConfigurations(
      state,
      action: PayloadAction<ConfigurationsState['isLoadingConfigurations']>,
    ) {
      state.isLoadingConfigurations = action.payload
    },
    setFilterConfigurations(
      state,
      action: PayloadAction<ConfigurationsState['filterConfigurations']>,
    ) {
      state.filterConfigurations = action.payload
    },
    setLastPageOfConfigurationsTable(state, action: PayloadAction<number>) {
      state.lastPageOfConfigurationsTable = action.payload
    },
    setIsAllConfigurationsFetched(state, action: PayloadAction<boolean>) {
      state.isAllConfigurationsFetched = action.payload
    },
    setDisplayFilterConfigurationsModal(state, action: PayloadAction<boolean>) {
      state.displayFilterConfigurationsModal = action.payload
    },
    setDisplaySetConfigurationModal(state, action: PayloadAction<boolean>) {
      state.displaySetConfigurationModal = action.payload
    },
    getValueOfNewConfiguration(
      state,
      action: PayloadAction<ConfigurationsState['newConfiguration']>,
    ) {
      state.newConfiguration = action.payload
    },
    setNewConfiguration(state) {},
  },
})

export const {
  actions: configurationsActions,
  reducer: configurationsReducer,
  name: sliceKey,
} = configurationsSlice

export const useConfigurationsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: configurationsReducer })
  useInjectSaga({ key: sliceKey, saga: configurationsSaga })
  return { configurationsActions: configurationsActions }
}
