import { Box, styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'

import { documentInvalidationsSelectors } from '../selectors'
import { documentInvalidationsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { mediaQueries } from 'styles/mediaQueries'

export const DocumentInvalidationsHeader = () => {
  const dispatch = useDispatch()

  const filterDocumentInvalidations = useSelector(
    documentInvalidationsSelectors.filterDocumentInvalidations,
  )
  const displayFilterDocumentInvalidationsModal = useSelector(
    documentInvalidationsSelectors.displayFilterDocumentInvalidationsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(
      documentInvalidationsActions.setDisplayFilterDocumentInvalidationsModal(
        true,
      ),
    )
  }

  const showablefilterDocumentInvalidations: IShowablefilterData[] = [
    { label: 'AdminID', value: filterDocumentInvalidations?.adminID },
    { label: 'UserCoreID', value: filterDocumentInvalidations?.userCoreID },
    {
      label: 'VouchererName',
      value: filterDocumentInvalidations?.vouchererName,
    },
    { label: 'Status', value: filterDocumentInvalidations?.status },
    {
      label: 'CreatedAt From',
      value: filterDocumentInvalidations?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterDocumentInvalidations?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterDocumentInvalidations?.updateAtFrom,
      isDate: true,
    },
    {
      label: 'UpdateAt To',
      value: filterDocumentInvalidations?.updateAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      documentInvalidationsActions.setFilterDocumentInvalidationsData({
        ...filterDocumentInvalidations,
        [label]: undefined,
      }),
    )
    dispatch(documentInvalidationsActions.getDocumentInvalidations())
  }

  return (
    <Wrapper>
      <TitleAndExportWrapper>
        <Title>Document Invalidations</Title>
        <SearchContainer onClick={handleOpenFilterModal}>
          <h4> Search</h4>
          <StyledSearchIcon />
        </SearchContainer>
      </TitleAndExportWrapper>
      <FilterSection>
        {!displayFilterDocumentInvalidationsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterDocumentInvalidations}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: flex-end;
`

const Title = styled('div')`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: var(--black-knight);
  ${mediaQueries.lessThan('md')`
    font-size: 28px;
    line-height: 32px;
    `}
  ${mediaQueries.lessThan('sm')`
    font-size: 24px;
    line-height: 28px;
    `}
`
const TitleAndExportWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const SearchContainer = styled(Box)`
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-wrap: nowrap;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: var(--white);
  background: var(--denim);
  border-radius: 8px;
  padding: 8px;
  width: 148px;

  h4 {
    display: inline;
    margin: 0;
    ${mediaQueries.lessThan('xs')`
      display: none;
      
      `}
  }
  ${mediaQueries.lessThan('xs')`
      width: 40px;
      `}
`

const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
