import { call, put, select, takeLatest } from 'redux-saga/effects'
import { emailInvalidationsActions } from './slice'
import { getDocumentInvalidationsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/helpers'
import { emailInvalidationsSelectors } from './selectors'
import { IFilterEmailInvalidations, IEmailInvalidation } from './types'

function* getEmailInvalidations(): any {
  yield put(emailInvalidationsActions.setIsLoadingEmailInvalidations(true))
  try {
    const filterEmailInvalidations: IFilterEmailInvalidations = yield select(
      emailInvalidationsSelectors.filterEmailInvalidations,
    )

    const response: AxiosResponse = yield call(
      getDocumentInvalidationsReq,
      filterEmailInvalidations,
    )

    const emailInvalidationsList = (response?.data?.data?.invalidations ||
      []) as IEmailInvalidation[]

    yield put(
      emailInvalidationsActions.setEmailInvalidations(emailInvalidationsList),
    )

    emailInvalidationsList.length === 0 ||
    emailInvalidationsList.length < filterEmailInvalidations.pageSize
      ? yield put(
          emailInvalidationsActions.setIsAllEmailInvalidationsFetched(true),
        )
      : yield put(
          emailInvalidationsActions.setIsAllEmailInvalidationsFetched(false),
        )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(emailInvalidationsActions.setIsLoadingEmailInvalidations(false))
  }
}

export function* emailInvalidationsSaga() {
  yield takeLatest(
    emailInvalidationsActions.getEmailInvalidations.type,
    getEmailInvalidations,
  )
}
