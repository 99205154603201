import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  addAdminReq,
  addRoleToAdminReq,
  blockUnblockAdminReq,
  deleteRoleFromAdminReq,
  fetchAdminsReq,
} from './providers'
import { adminsActions } from './slice'
import { AxiosResponse } from 'axios'
import { AdminsState } from './types'
import { adminsDomains } from './selectors'
import { errorMessage, successMessage } from 'utils/helpers'
import { customToast } from 'app/components/toast/CustomToast'

function* fetchAdmins(action: ReturnType<typeof adminsActions.fetchAdmins>) {
  if (!action.payload.silent) {
    yield put(adminsActions.setIsLoadingAdmins(true))
  }
  try {
    const response: AxiosResponse = yield call(fetchAdminsReq)
    yield put(adminsActions.setAdminsList(response?.data?.data))
  } catch (error) {
    console.log('Error fetching roles', error)
    customToast.error(errorMessage(error))
  } finally {
    if (!action.payload.silent) {
      yield put(adminsActions.setIsLoadingAdmins(false))
    }
  }
}

function* deleteRoleFromAdmin() {
  const removeRouteFromRole: AdminsState['deleteConfirmationModal'] =
    yield select(adminsDomains.deleteConfirmationModal)
  try {
    const response: AxiosResponse = yield call(
      deleteRoleFromAdminReq,
      removeRouteFromRole,
    )
    customToast.success(successMessage(response))
    yield put(adminsActions.fetchAdmins({ silent: true }))
  } catch (error) {
    customToast.error(errorMessage(error))
    console.log('Error deleting route from role', error)
  }
}

function* addRoleToAdmin(
  action: ReturnType<typeof adminsActions.addRoleToAdmin>,
) {
  yield put(adminsActions.setIsRoleAdding(action.payload?.role?.id))
  try {
    const response: AxiosResponse = yield call(addRoleToAdminReq, action)
    customToast.success(successMessage(response))
    yield put(adminsActions.fetchAdmins({ silent: true }))
  } catch (error) {
    customToast.error(errorMessage(error))
    console.log('Error adding route to role', error)
  } finally {
    yield put(adminsActions.setIsRoleAdding(null))
  }
}

function* blockUnblockAdmin(
  action: ReturnType<typeof adminsActions.blockUnblockAdmin>,
) {
  try {
    const response: AxiosResponse = yield call(blockUnblockAdminReq, action)
    customToast.success(successMessage(response))
    yield put(adminsActions.fetchAdmins({ silent: true }))
  } catch (error) {
    customToast.error(errorMessage(error))
    console.log('Error blocking admin', error)
  }
}

function* addAdmin(action: ReturnType<typeof adminsActions.addAdmin>) {
  try {
    const response: AxiosResponse = yield call(addAdminReq, action)
    customToast.success(successMessage(response))
    yield put(adminsActions.fetchAdmins({ silent: true }))
  } catch (error) {
    console.log('Error adding role', error)
    customToast.error(errorMessage(error))
  }
}

export function* adminsSaga() {
  yield takeLatest(adminsActions.fetchAdmins.type, fetchAdmins)
  yield takeLatest(adminsActions.deleteRoleFromAdmin.type, deleteRoleFromAdmin)
  yield takeLatest(adminsActions.addRoleToAdmin.type, addRoleToAdmin)
  yield takeLatest(adminsActions.blockUnblockAdmin.type, blockUnblockAdmin)
  yield takeLatest(adminsActions.addAdmin.type, addAdmin)
}
