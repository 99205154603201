import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resubmissionItemActions } from './slice'
import { resubmissionItemSelectors } from './selectors'
import { IKYCProviderAddress, IKYCProviderData } from './types'
import { getIn } from 'formik'
import { isIKYCProviderAddress } from './providers/utils'
import { ResubmissionRow } from './components/ResubmissionRow'
import { customToast } from 'app/components/toast/CustomToast'
import { FIELD_ORDER } from './constants'
import { PageLoading } from 'app/components/common/loading/pageLoading'
import { ConfirmationModal } from './components/modals/confirmation'
import { redirectionToSumsub } from 'utils/redirections'
import PrimaryButton from 'app/components/button/primary-button'
import { useResubmitionItemvalidations } from './components/useResubmitionItemvalidations'
import {
  HeaderContainer,
  HeaderTitle,
  Title,
  TitleAndExportWrapper,
} from './styles'

export function ResubmissionItem() {
  const [searchParams] = useSearchParams()

  const isSubmitLoading = useSelector(resubmissionItemSelectors.isSubmitLoading)
  const dispatch = useDispatch()
  const isLoading = useSelector(resubmissionItemSelectors.isLoading)
  const resubmissionItem = useSelector(
    resubmissionItemSelectors.resubmissionItem,
  )

  const { formik, initialValues } = useResubmitionItemvalidations()

  const refrenceId = searchParams.get('referenceId')
  const userReviewStatus = searchParams.get('userReviewStatus')
  const providerStatus = searchParams.get('providerStatus')

  useEffect(() => {
    refrenceId &&
      dispatch(resubmissionItemActions.fetchResubmissionItem(refrenceId))
  }, [refrenceId])

  const hadleFormSubmit = () => {
    formik.validateForm().then(errors => {
      if (Object.keys(errors).length > 0) {
        Object.keys(errors).forEach(fieldName => {
          formik.setFieldTouched(fieldName, true)
        })

        const firstErrorField = FIELD_ORDER.find((field: string) =>
          errors.hasOwnProperty(field),
        )

        const firstErrorFieldElement = document.querySelector<HTMLInputElement>(
          `[name="${firstErrorField}"]`,
        )

        if (firstErrorFieldElement) {
          firstErrorFieldElement.scrollIntoView({
            behavior: 'smooth',
          })
        }

        customToast.error('Please fill all the required fields properly')
      } else {
        formik.handleSubmit()
        dispatch(resubmissionItemActions.setDisplayConfirmationModal(true))
      }
    })
  }

  return (
    <>
      {isLoading ? (
        <PageLoading />
      ) : (
        <div>
          <TitleAndExportWrapper>
            <Title>
              Resubmission Item for id doc:{' '}
              {resubmissionItem.kycProviderData.idDocType}
            </Title>
            <PrimaryButton
              onClick={() => redirectionToSumsub(resubmissionItem.applicantId)}
            >
              View in Sumsub
            </PrimaryButton>
          </TitleAndExportWrapper>
          <HeaderContainer>
            <HeaderTitle>Field</HeaderTitle>
            <HeaderTitle>Provider Suggestion</HeaderTitle>

            <HeaderTitle>User Suggestion</HeaderTitle>
            <HeaderTitle>Officer Input</HeaderTitle>
          </HeaderContainer>
          {Object.keys(initialValues).map(key => {
            const safeKey = key as keyof IKYCProviderData

            if (
              typeof initialValues[safeKey] === 'object' &&
              initialValues[safeKey] !== null &&
              isIKYCProviderAddress(initialValues[safeKey])
            ) {
              return Object.keys(initialValues[safeKey] as Object).map(
                nestedKey => {
                  const nestedSafeKey = nestedKey as keyof IKYCProviderAddress

                  return (
                    <ResubmissionRow
                      key={`${key}.${nestedSafeKey}`}
                      name={`${key}.${nestedSafeKey}`}
                      onChange={formik.handleChange}
                      onFormikValueChange={value => {
                        formik.setFieldValue(`${key}.${nestedSafeKey}`, value)
                      }}
                      value={
                        formik.values[safeKey]
                          ? (formik.values[safeKey] as IKYCProviderAddress)[
                              nestedSafeKey
                            ]
                          : ''
                      }
                      hasError={
                        formik.touched[safeKey] &&
                        !!getIn(formik.errors, `${safeKey}.${nestedSafeKey}`)
                      }
                      userValue={
                        resubmissionItem.userSuggestion[safeKey]
                          ? (
                              resubmissionItem.userSuggestion[
                                safeKey
                              ] as IKYCProviderAddress
                            )[nestedSafeKey]
                          : ''
                      }
                      providerValue={
                        resubmissionItem.kycProviderData[safeKey]
                          ? (
                              resubmissionItem.kycProviderData[
                                safeKey
                              ] as IKYCProviderAddress
                            )[nestedSafeKey]
                          : ''
                      }
                      errorText={
                        formik.touched[safeKey] &&
                        getIn(formik.errors, `${safeKey}.${nestedSafeKey}`)
                          ? (getIn(
                              formik.errors,
                              `${safeKey}.${nestedSafeKey}`,
                            ) as string)
                          : ''
                      }
                    />
                  )
                },
              )
            }

            return (
              <ResubmissionRow
                key={key}
                name={key}
                onChange={formik.handleChange}
                onFormikValueChange={value => {
                  formik.setFieldValue(key, value)
                }}
                value={formik.values[safeKey]?.toString() || ''}
                hasError={formik.touched[safeKey] && !!formik.errors[safeKey]}
                userValue={
                  resubmissionItem.userSuggestion[safeKey]?.toString() || ''
                }
                providerValue={
                  resubmissionItem.kycProviderData[safeKey]?.toString() || ''
                }
                errorText={
                  formik.touched[safeKey]
                    ? (formik.errors[safeKey] as string)
                    : ''
                }
              />
            )
          })}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '20px',
              marginTop: '20px',
            }}
          >
            <PrimaryButton
              onClick={() => {
                hadleFormSubmit()
              }}
              type="submit"
              style={{ margin: '16px 0' }}
              isLoading={isSubmitLoading}
              disabled={
                !(
                  userReviewStatus === 'REJECTED' ||
                  providerStatus === 'OFFICER_PROVIDER_PENDING'
                )
              }
            >
              {isSubmitLoading ? 'Submitting...' : 'Submit'}
            </PrimaryButton>
          </div>
        </div>
      )}
      <ConfirmationModal />
    </>
  )
}
