import { PutEffect, all, put, takeLatest } from 'redux-saga/effects'
import { Submission, globalActions } from './slice'
import { EventLog, Provider } from 'corebc'
import { Registery, Registery__factory } from 'contracts/registry'
import { ContractNames, DocumentSubmitedTypes } from 'app/constants'
import { decodeByteToString, getSha3FromString } from 'utils/sha3-for-sc'
import { KYCVault, KYCVault__factory } from 'contracts/kyc-valut'
import { getRpcProvider } from 'utils/get-provider'
import { Action } from 'redux-saga'

function* fetchContractAddressess() {
  try {
    // Set loading state
    yield put(globalActions.setIsLoadingContractAddresses(true))

    // Obtain RPC provider
    const provider: Provider = getRpcProvider()

    // Connect to the Registry smart contract
    const registryContract: Registery = Registery__factory.connect(
      process.env.REACT_APP_REGISTERY_CONTRACT_ADDRESS as string,
      provider,
    )

    // Fetch all contract addresses
    const contractAddresses: { [key: string]: string } =
      yield registryContract.getAll()

    // Extract contract names and addresses
    const sha3ContractNames = contractAddresses[0]
    const sha3ContractAddresses = contractAddresses[1]

    // we create a toDispatch array to push all the put effects push into it
    // and then we use the all effect to run all the put effects at once
    const toDispatch: PutEffect<Action>[] = []

    Object.values(ContractNames).map(contractName => {
      // Convert contract name to SHA3 hash
      const sha3ContractName = getSha3FromString(contractName)
      // Find index of contract name in the list
      const indexContractName = sha3ContractNames.indexOf(sha3ContractName)
      // Get corresponding contract address
      const sha3ContractAddress = sha3ContractAddresses[indexContractName]
      // convert the contract address from bytes to string
      const contractAddress = decodeByteToString(sha3ContractAddress)
      // push the put effect into the toDispatch array
      toDispatch.push(
        put(
          globalActions.setContractAddresses({
            contractName,
            contractAddress,
          }),
        ),
      )
    })
    // Dispatch all actions
    yield all(toDispatch)
  } catch (error) {
    console.log('fetchContractAddresses error', error)
  } finally {
    // Set loading state
    yield put(globalActions.setIsLoadingContractAddresses(false))
  }
}

function* fetchAllKYCValutSubmissions(
  action: ReturnType<typeof globalActions.fetchAllKYCValutSubmissions>,
) {
  try {
    const provider: Provider = getRpcProvider()
    const kycVaultContract: KYCVault = KYCVault__factory.connect(
      action.payload.kycVaultcontractAddress,
      provider,
    )

    const fromBlock = 0 // Replace with contract's deployment block number
    const toBlock = 'latest'
    // Fetch all past "Submitted" events
    let submittedFilter = kycVaultContract.filters.Submitted()
    const submittedEvents: EventLog[] = yield kycVaultContract.queryFilter(
      submittedFilter,
      fromBlock,
      toBlock,
    )
    // we create a toDispatch array to push all the put effects push into it
    // and then we use the all effect to run all the put effects at once
    const toDispatch: any[] = []
    submittedEvents?.map(event => {
      const submissionId = event?.topics[1]
      toDispatch.push(kycVaultContract.submission(submissionId))
    })

    const submissonResults: Submission[] = yield all(toDispatch)

    // categorize the submissions
    const address: EventLog[] = []
    const idCard: EventLog[] = []
    const passport: EventLog[] = []
    const residencePermit: EventLog[] = []
    const driverLicense: EventLog[] = []
    const email: EventLog[] = []
    const phone: EventLog[] = []

    // hash the document types names
    const hashedEmail = getSha3FromString(DocumentSubmitedTypes.EMAIL)
    const hashedPhone = getSha3FromString(DocumentSubmitedTypes.PHONE)
    const hashedIDCard = getSha3FromString(DocumentSubmitedTypes.IDCARD)
    const hashedDriverLicense = getSha3FromString(
      DocumentSubmitedTypes.DRIVERLICENSE,
    )
    const hashedResidencePermit = getSha3FromString(
      DocumentSubmitedTypes.RESIDENCEPERMIT,
    )
    const hashedPoa = getSha3FromString(DocumentSubmitedTypes.ADDRESS)
    const hashedPassport = getSha3FromString(DocumentSubmitedTypes.PASSPORT)

    // categorize the submissions based on the document type
    submissonResults?.forEach((submission: any, index: number) => {
      const submissionRole = submission[0]
      switch (submissionRole) {
        case hashedEmail:
          email.push(submittedEvents[index])
          break
        case hashedPhone:
          phone.push(submittedEvents[index])
          break
        case hashedIDCard:
          idCard.push(submittedEvents[index])
          break
        case hashedDriverLicense:
          driverLicense.push(submittedEvents[index])
          break
        case hashedResidencePermit:
          residencePermit.push(submittedEvents[index])
          break
        case hashedPoa:
          address.push(submittedEvents[index])
          break
        case hashedPassport:
          passport.push(submittedEvents[index])
          break
      }
    })

    // update the Submitted Submissions in the store
    yield put(
      globalActions.setSubmittedSubmissions({
        address,
        idCard,
        passport,
        residencePermit,
        driverLicense,
        email,
        phone,
      }),
    )

    // listen for new "submitted" events
    kycVaultContract.on(submittedFilter, submission => {})

    //  Fetch all past Invalidated events
    let InvalidatedFilter = kycVaultContract.filters.Invalidated()
    const InvalidatedEvents: EventLog[] = yield kycVaultContract.queryFilter(
      InvalidatedFilter,
      fromBlock,
      toBlock,
    )
    yield put(globalActions.setInvalidatedSubmissions(InvalidatedEvents))

    // listen for new "invalidate" events
    kycVaultContract.on(InvalidatedFilter, invalidated => {})
  } catch (error) {
    console.log('fetchContractAddresses error', error)
  }
}

export function* globalSaga() {
  yield takeLatest(
    globalActions.fetchContractAddresses.type,
    fetchContractAddressess,
  ),
    yield takeLatest(
      globalActions.fetchAllKYCValutSubmissions.type,
      fetchAllKYCValutSubmissions,
    )
}
