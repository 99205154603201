import { call, put, select, takeLatest } from 'redux-saga/effects'
import { botOrdersActions } from './slice'
import { getBotOrdersReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/helpers'
import { botOrdersSelectors } from './selectors'
import { IFilterBotOrders, IBotOrder } from './types'

function* getBotOrders(): any {
  yield put(botOrdersActions.setIsLoadingBotOrders(true))
  try {
    const filterBotOrders: IFilterBotOrders = yield select(
      botOrdersSelectors.filterBotOrders,
    )

    const response: AxiosResponse = yield call(getBotOrdersReq, filterBotOrders)

    const botOrdersList = (response?.data?.data?.botOrders || []) as IBotOrder[]

    yield put(botOrdersActions.setBotOrders(botOrdersList))

    botOrdersList.length === 0 ||
    botOrdersList.length < filterBotOrders.pageSize
      ? yield put(botOrdersActions.setIsAllBotOrdersFetched(true))
      : yield put(botOrdersActions.setIsAllBotOrdersFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(botOrdersActions.setIsLoadingBotOrders(false))
  }
}

export function* botOrdersSaga() {
  yield takeLatest(botOrdersActions.getBotOrders.type, getBotOrders)
}
