import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { phoneVerificationsActions } from './slice'
import { getPhoneVerificationsDataReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/helpers'
import { IPhoneVerification, IFilterPhoneVerifications } from './types'
import { phoneVerificationsSelectors } from './selectors'

function* getPhoneVerifications(): any {
  yield put(phoneVerificationsActions.setIsLoadingPhoneVerifications(true))

  try {
    const filterPhoneVerifications: IFilterPhoneVerifications = yield select(
      phoneVerificationsSelectors.filterPhoneVerifications,
    )

    const response: AxiosResponse = yield call(
      getPhoneVerificationsDataReq,
      filterPhoneVerifications,
    )

    const phoneVerificationsList =
      (response?.data?.data?.phones as IPhoneVerification[]) ?? []

    yield put(
      phoneVerificationsActions.setPhoneVerificationsList(
        phoneVerificationsList,
      ),
    )
    phoneVerificationsList.length === 0 ||
    phoneVerificationsList.length < filterPhoneVerifications.pageSize
      ? yield put(
          phoneVerificationsActions.setIsAllPhoneVerificationsFetched(true),
        )
      : yield put(
          phoneVerificationsActions.setIsAllPhoneVerificationsFetched(false),
        )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(phoneVerificationsActions.setIsLoadingPhoneVerifications(false))
  }
}

export function* phoneVerificationsSaga() {
  yield takeLatest(
    phoneVerificationsActions.getPhoneVerifications.type,
    getPhoneVerifications,
  )
}
