import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterAssetsManagement } from '../types'
import { OrdersEndpoints } from 'api/endpoints/shop'
import { AssetsManagementEndpoints } from 'api/endpoints/ping'

export function* getAssetsManagementReq(filterData: IFilterAssetsManagement) {
  const response: AxiosResponse = yield axiosInstance.get(
    AssetsManagementEndpoints.assetsManagementList(filterData),
  )
  return response
}
