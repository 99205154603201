import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { submissionsActions } from './slice'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage, successMessage } from 'utils/helpers'
import { Submission } from './types'
import { getRpcProvider } from 'utils/get-provider'
import { KYCVault, KYCVault__factory } from 'contracts/kyc-valut'
import { Block, EventLog, Provider } from 'corebc'
import { globalDomains } from '../Initialize/selector'
import { DocumentSubmitedTypes } from 'app/constants'
import { getAllRolesHashedName } from './utils/getAllRolesHashedName'
import { invalidateTheSubmissionReq } from './providers'
import { submissionsDomains } from './selectors'
import { SubmissionStructOutput } from 'contracts/kyc-valut/KYCVault'

function* getSubmissionsByAddress(
  action: ReturnType<typeof submissionsActions.getSubmissionsByAddress>,
) {
  yield put(submissionsActions.setIsLoadingSubmissions(true))
  try {
    const address = action.payload
    const provider: Provider = getRpcProvider()
    const { KYCVault } = yield select(globalDomains.contractAddresses)
    const kycVaultContract: KYCVault = KYCVault__factory.connect(
      KYCVault,
      provider,
    )

    const fromBlock = 0 // Replace with contract's deployment block number
    const toBlock = 'latest'

    // Fetch all past "Submitted" events for specific user
    let speceficUserSubmittedFilter = kycVaultContract.filters.Submitted(
      undefined,
      address,
    )
    const speceficUserSubmittedEvents: EventLog[] =
      yield kycVaultContract.queryFilter(
        speceficUserSubmittedFilter,
        fromBlock,
        toBlock,
      )

    // we create a submissionsToDispatch and timestampsToDispatch array
    //to push all the put effects push into it and then we use the all
    // effect to run all the put effects at once
    const submissionsToDispatch: any[] = []
    const timestampsToDispatch: any[] = []
    speceficUserSubmittedEvents?.map(event => {
      const submissionId = event?.topics[1]
      const submissionBlockNumber = event.blockNumber
      submissionsToDispatch.push(kycVaultContract.submission(submissionId))
      timestampsToDispatch.push(provider.getBlock(submissionBlockNumber))
    })

    const submissonResponses: SubmissionStructOutput[] = yield all(
      submissionsToDispatch,
    )
    const timestamps: Block[] = yield all(timestampsToDispatch)

    // hash the document types names
    const {
      hashedEmail,
      hashedPhone,
      hashedIDCard,
      hashedDriverLicense,
      hashedResidencePermit,
      hashedAddress,
      hashedPassport,
    } = getAllRolesHashedName()

    // the array that will hold the submissions with their roles
    const specificUserSubmittedEventsWithRole: any[] = []

    // categorize the submissions based on the document type
    submissonResponses?.forEach((submission, index: number) => {
      // the zero index is the role of the submission
      const submissionRole = submission[0]
      // the forth index is the isInvalidated flag
      const isInvalidated = submission[4]

      switch (submissionRole) {
        case hashedEmail:
          specificUserSubmittedEventsWithRole.push({
            role: DocumentSubmitedTypes.EMAIL,
            isInvalidated,
            timestamp: timestamps[index]?.timestamp,
            ...speceficUserSubmittedEvents[index],
          })
          break
        case hashedPhone:
          specificUserSubmittedEventsWithRole.push({
            role: DocumentSubmitedTypes.PHONE,
            isInvalidated,
            timestamp: timestamps[index]?.timestamp,
            ...speceficUserSubmittedEvents[index],
          })
          break
        case hashedIDCard:
          specificUserSubmittedEventsWithRole.push({
            role: DocumentSubmitedTypes.IDCARD,
            isInvalidated,
            timestamp: timestamps[index]?.timestamp,
            ...speceficUserSubmittedEvents[index],
          })
          break
        case hashedDriverLicense:
          specificUserSubmittedEventsWithRole.push({
            role: DocumentSubmitedTypes.DRIVERLICENSE,
            isInvalidated,
            timestamp: timestamps[index]?.timestamp,
            ...speceficUserSubmittedEvents[index],
          })
          break
        case hashedResidencePermit:
          specificUserSubmittedEventsWithRole.push({
            role: DocumentSubmitedTypes.RESIDENCEPERMIT,
            isInvalidated,
            timestamp: timestamps[index]?.timestamp,
            ...speceficUserSubmittedEvents[index],
          })
          break
        case hashedAddress:
          specificUserSubmittedEventsWithRole.push({
            role: DocumentSubmitedTypes.ADDRESS,
            isInvalidated,
            timestamp: timestamps[index]?.timestamp,
            ...speceficUserSubmittedEvents[index],
          })
          break
        case hashedPassport:
          specificUserSubmittedEventsWithRole.push({
            role: DocumentSubmitedTypes.PASSPORT,
            isInvalidated,
            timestamp: timestamps[index]?.timestamp,
            ...speceficUserSubmittedEvents[index],
          })
          break
      }
    })

    yield put(
      submissionsActions.setSubmissions(specificUserSubmittedEventsWithRole),
    )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(submissionsActions.setIsLoadingSubmissions(false))
  }
}

function* invalidateTheSubmission(
  action: ReturnType<typeof submissionsActions.invalidateTheSubmission>,
) {
  try {
    yield put(submissionsActions.setIsInvalidating(true))
    const response: AxiosResponse = yield call(
      invalidateTheSubmissionReq,
      action,
    )
    yield put(submissionsActions.setConfirmDeleteModalItem(undefined))
    customToast.success(successMessage(response))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(submissionsActions.setIsInvalidating(false))
  }
}

function* fetchAllInvalidatedSubmissions() {
  try {
    const provider: Provider = getRpcProvider()
    const { KYCVault } = yield select(globalDomains.contractAddresses)
    const kycVaultContract: KYCVault = KYCVault__factory.connect(
      KYCVault,
      provider,
    )

    const fromBlock = 0 // Replace with contract's deployment block number
    const toBlock = 'latest'

    //  Fetch all past Invalidated events
    let InvalidatedFilter = kycVaultContract.filters.Invalidated()
    const InvalidatedEvents: EventLog[] = yield kycVaultContract.queryFilter(
      InvalidatedFilter,
      fromBlock,
      toBlock,
    )

    yield put(submissionsActions.setInvalidated(InvalidatedEvents))
  } catch (error) {
    console.log('fetchContractAddresses error', error)
  }
}

export function* submissionsSaga() {
  yield takeLatest(
    submissionsActions.getSubmissionsByAddress.type,
    getSubmissionsByAddress,
  )
  yield takeLatest(
    submissionsActions.invalidateTheSubmission.type,
    invalidateTheSubmission,
  )
  yield takeLatest(
    submissionsActions.fetchAllInvalidatedSubmissions.type,
    fetchAllInvalidatedSubmissions,
  )
}
