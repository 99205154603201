import { useDispatch } from 'react-redux'
import { vouchersActions } from '../../slice'
import { Box, styled } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { IssueVoucherModal } from './addVoucherModal'
import { mediaQueries } from 'styles/mediaQueries'

export const IssueVoucherButton = () => {
  const dispatch = useDispatch()
  return (
    <>
      <CTAContainer
        onClick={() => dispatch(vouchersActions.setDisplayVoucherModal(true))}
      >
        <h4> Issue Voucher</h4>
        <AddIcon />
      </CTAContainer>
      <IssueVoucherModal />
    </>
  )
}

const CTAContainer = styled(Box)`
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-wrap: nowrap;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: var(--white);
  background: var(--denim);
  border-radius: 8px;
  padding: 8px;
  width: 148px;
  height: 40px;

  h4 {
    display: inline;
    margin: 0;
    ${mediaQueries.lessThan('xs')`
      display: none;
      
      `}
  }
  ${mediaQueries.lessThan('xs')`
      width: 40px;
      `}
`
