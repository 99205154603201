//FIXME: we must remove this folder and this is a templete

import { ConfigurationsHeader } from './components/Header'
import { FilterConfigurationsModal } from './components/modals/filter'
import { SetConfigurationModal } from './components/modals/setConfiguration'
import { ConfigurationsTable } from './components/table'

interface Props {}

export function Configurations(props: Props) {
  return (
    <>
      <ConfigurationsHeader />
      <ConfigurationsTable />
      <FilterConfigurationsModal />
      <SetConfigurationModal />
    </>
  )
}
