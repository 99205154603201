//FIXME: we must remove this folder and this is a templete

import { Header } from './components/Header'
import { FilterClientsModal } from './components/modals/filter'
import { TableOfData } from './components/table'

interface Props {}

export function Clients(props: Props) {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterClientsModal />
    </>
  )
}
