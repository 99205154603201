//FIXME: we must remove this folder and this is a templete

import { Header } from './components/Header'
import { FilterClientSourceKeysModal } from './components/modals/filter'
import { TableOfData } from './components/table'

interface Props {}

export function ClientSourceKeys(props: Props) {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterClientSourceKeysModal />
    </>
  )
}
