import { call, put, select, takeLatest } from 'redux-saga/effects'
import { emailRejectionsActions } from './slice'
import { getEmailRejectionsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/helpers'
import { emailRejectionsSelectors } from './selectors'
import { IFilterEmailRejections, IEmailRejection } from './types'

function* getEmailRejections(): any {
  yield put(emailRejectionsActions.setIsLoadingEmailRejections(true))
  try {
    const filterEmailRejections: IFilterEmailRejections = yield select(
      emailRejectionsSelectors.filterDataEmailRejections,
    )

    const response: AxiosResponse = yield call(
      getEmailRejectionsReq,
      filterEmailRejections,
    )

    const emailRejectionsList = (response?.data?.data?.rejections ||
      []) as IEmailRejection[]

    yield put(emailRejectionsActions.setEmailRejections(emailRejectionsList))

    emailRejectionsList.length === 0 ||
    emailRejectionsList.length < filterEmailRejections.pageSize
      ? yield put(emailRejectionsActions.setIsAllEmailRejectionsFetched(true))
      : yield put(emailRejectionsActions.setIsAllEmailRejectionsFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(emailRejectionsActions.setIsLoadingEmailRejections(false))
  }
}

export function* emailRejectionsSaga() {
  yield takeLatest(
    emailRejectionsActions.getEmailRejections.type,
    getEmailRejections,
  )
}
