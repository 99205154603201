import { useDispatch, useSelector } from 'react-redux'
import { ConfirmationContent } from './content'
import { resubmissionItemActions } from 'app/containers/ResubmissionItem/slice'
import { resubmissionItemDomains } from 'app/containers/ResubmissionItem/selectors'
import { FC } from 'react'
import { Modal } from 'app/components/modal'

interface IConfirmationModalProps {}

export const ConfirmationModal: FC<IConfirmationModalProps> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(resubmissionItemDomains.displayConfirmationModal)
  const handleModalClose = () => {
    dispatch(resubmissionItemActions.setDisplayConfirmationModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ConfirmationContent />
    </Modal>
  )
}
