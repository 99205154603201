import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getLocalStorageOptions } from 'app/constants'

export const OAUTHS_TABLE_KEY = 'oauthsTable'

export const columnsForShowInOauthsTable: ColumnOfTable[] = [
  { id: 'name', label: 'Name' },
  { id: 'clientDomainID', label: 'Client DomainID' },
  { id: 'clientDomainUuid', label: 'Client DomainUUID' },
  { id: 'uuID', label: 'UUID' },
  { id: 'oauth2ClientID', label: 'Oauth2 ClientID' },
  { id: 'secret', label: 'Secret' },
  { id: 'scope', label: 'Scope' },
  { id: 'tokenEndpointAuthMethod', label: 'Token Endpoint Auth Method' },
  { id: 'grantTypes', label: 'Grant Types' },
  { id: 'responseTypes', label: 'Response Types' },
  { id: 'redirectUris', label: 'Redirect Uris' },
  { id: 'audiencees', label: 'Audiencees' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updateAt', label: 'Update At', isDate: true },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getLocalStorageOptions({ tableKey: OAUTHS_TABLE_KEY })
