import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SetConfigurationContent } from './content'
import { configurationsSelectors } from 'app/containers/Configurations/selectors'
import { configurationsActions } from 'app/containers/Configurations/slice'
import { Modal } from 'app/components/modal'

interface IFilterConfigurationsModalProps {}

export const SetConfigurationModal: FC<
  IFilterConfigurationsModalProps
> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    configurationsSelectors.displaySetConfigurationModal,
  )
  const handleModalClose = () => {
    dispatch(configurationsActions.setDisplaySetConfigurationModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <SetConfigurationContent />
    </Modal>
  )
}
