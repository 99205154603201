import { call, put, select, takeLatest } from 'redux-saga/effects'
import { paymentsActions } from './slice'
import { getPaymentReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/helpers'
import { paymentsSelectors } from './selectors'
import { IFilterPayments, IPayment } from './types'

function* getPayments(): any {
  yield put(paymentsActions.setIsLoadingPayments(true))
  try {
    const filterPayments: IFilterPayments = yield select(
      paymentsSelectors.filterPayments,
    )

    const response: AxiosResponse = yield call(getPaymentReq, filterPayments)

    const paymentsList = (response?.data?.data?.payments || []) as IPayment[]

    yield put(paymentsActions.setPayments(paymentsList))

    paymentsList.length === 0 || paymentsList.length < filterPayments.pageSize
      ? yield put(paymentsActions.setIsAllPaymentsFetched(true))
      : yield put(paymentsActions.setIsAllPaymentsFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(paymentsActions.setIsLoadingPayments(false))
  }
}

export function* paymentsSaga() {
  yield takeLatest(paymentsActions.getPayments.type, getPayments)
}
