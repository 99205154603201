import { useDispatch, useSelector } from 'react-redux'
import { FilterTransmissionsContent } from './content'
import { FC } from 'react'
import { transmissionsSelectors } from 'app/containers/Transmissions/selectors'
import { transmissionsActions } from 'app/containers/Transmissions/slice'
import { Modal } from 'app/components/modal'

interface IFilterModalProps {}

export const FilterTransmissionsModal: FC<IFilterModalProps> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    transmissionsSelectors.displayFilterTransmissionsModal,
  )
  const handleModalClose = () => {
    dispatch(transmissionsActions.setDisplayFilterTransmissionsModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterTransmissionsContent />
    </Modal>
  )
}
