import { FC, useState } from 'react'
import { Box, styled } from '@mui/material'
import { useDispatch } from 'react-redux'
import BaseDropdownInput from 'app/components/input/dropdownInput'
import BaseInput from 'app/components/input/baseInput'
import { vouchersActions } from 'app/containers/Vouchers/slice'
import { IIssueVoucher } from 'app/containers/Vouchers/types'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import { VoucherNames } from '../constants'
import { mediaQueries } from 'styles/mediaQueries'
import PrimaryButton from 'app/components/button/primary-button'

interface IIssueVoucherContentProps {}

export const IssueVoucherContent: FC<IIssueVoucherContentProps> = () => {
  const dispatch = useDispatch()

  const [voucherData, setVoucherData] = useState<IIssueVoucher>({
    amount: 1,
    userCoreID: '',
    voucherName: '',
  })

  const isVoucherDataEmpty = Object.values(voucherData).some(
    value => value === '' || !value,
  )

  const handleAddVoucher = () => {
    dispatch(vouchersActions.setDisplayVoucherModal(false))
    dispatch(vouchersActions.issueVoucher(voucherData))
  }

  return (
    <Wrapper>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Voucher Name:</ItemLabel>
          <BaseDropdownInput
            label="Voucher Name"
            name="voucherName"
            placeholder="Voucher Name"
            fullWidth
            options={VoucherNames}
            onChange={event => {
              setVoucherData({
                ...voucherData,
                voucherName: event.target.value as string,
              })
            }}
            value={voucherData.voucherName}
            endAdornment={
              voucherData?.voucherName ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    setVoucherData({
                      ...voucherData,
                      voucherName: '',
                    })
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>

        <ItemConatiner>
          <ItemLabel>User CoreID:</ItemLabel>
          <BaseInput
            value={voucherData.userCoreID}
            onChange={event => {
              setVoucherData({
                ...voucherData,
                userCoreID: event.target.value,
              })
            }}
            placeholder="User CoreID"
            fullWidth
          />
        </ItemConatiner>
      </TwoItemsContainer>
      {/* <TwoItemsContainer>
         please dont remove this 
         <ItemConatiner>
          <ItemLabel>Amount:</ItemLabel>
          <BaseInput
            value={voucherData.amount}
            type="number"
            onChange={event => {
              const selectedAmount = parseInt(event.target.value as string, 10)
              setVoucherData({
                ...voucherData,
                amount: selectedAmount,
              })
            }}
            placeholder="Amount"
            fullWidth
          />
        </ItemConatiner> 

        <ItemConatiner />
      </TwoItemsContainer> */}
      <CTAContainer>
        <PrimaryButtons
          onClick={handleAddVoucher}
          disabled={isVoucherDataEmpty}
        >
          Issue Voucher
        </PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  min-width: 452px;
  ${mediaQueries.lessThan('lg')`
  min-width: 417px;
  `}
  ${mediaQueries.lessThan('md')`
  min-width: 239px;
  `}
  ${mediaQueries.lessThan('sm')`
  min-width: 241px;
  `}
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`

const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
