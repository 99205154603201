import { call, put, select, takeLatest } from 'redux-saga/effects'
import { assetsManagementActions } from './slice'
import { getAssetsManagementReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/helpers'
import { assetsManagementSelectors } from './selectors'
import { IAssetManagement, IFilterAssetsManagement } from './types'

function* getAssetsManagement(): any {
  yield put(assetsManagementActions.setIsLoadingAssetsManagement(true))
  try {
    const filterAssetsManagement: IFilterAssetsManagement = yield select(
      assetsManagementSelectors.filterAssetsManagement,
    )

    const response: AxiosResponse = yield call(
      getAssetsManagementReq,
      filterAssetsManagement,
    )

    const assetsManagementList = (response?.data?.data?.assetsManagement ||
      []) as IAssetManagement[]

    yield put(assetsManagementActions.setAssetsManagement(assetsManagementList))

    assetsManagementList.length === 0 ||
    assetsManagementList.length < filterAssetsManagement.pageSize
      ? yield put(assetsManagementActions.setIsAllAssetsManagementFetched(true))
      : yield put(
          assetsManagementActions.setIsAllAssetsManagementFetched(false),
        )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(assetsManagementActions.setIsLoadingAssetsManagement(false))
  }
}

export function* assetsManagementSaga() {
  yield takeLatest(
    assetsManagementActions.getAssetsManagement.type,
    getAssetsManagement,
  )
}
