/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { ClientsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { clientsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

// The initial state of the Overview container
export const initialState: ClientsState = {
  clientsList: [],
  isLoadingClients: false,
  filterClients: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfClientsTable: 0,
  isAllClientsFetched: false,
  displayFilterClientsModal: false,
}

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    getClientsList(state) {},
    setClients(state, action: PayloadAction<ClientsState['clientsList']>) {
      state.clientsList = action.payload
    },
    setIsLoadingClients(
      state,
      action: PayloadAction<ClientsState['isLoadingClients']>,
    ) {
      state.isLoadingClients = action.payload
    },
    setFilterClients(
      state,
      action: PayloadAction<ClientsState['filterClients']>,
    ) {
      state.filterClients = action.payload
    },
    setLastPageOfClientsTable(state, action: PayloadAction<number>) {
      state.lastPageOfClientsTable = action.payload
    },
    setIsAllClientsFetched(state, action: PayloadAction<boolean>) {
      state.isAllClientsFetched = action.payload
    },
    setDisplayFilterClientsModal(state, action: PayloadAction<boolean>) {
      state.displayFilterClientsModal = action.payload
    },
  },
})

export const {
  actions: clientsActions,
  reducer: clientsReducer,
  name: sliceKey,
} = clientsSlice

export const useClientsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: clientsReducer })
  useInjectSaga({ key: sliceKey, saga: clientsSaga })
  return { clientsActions: clientsActions }
}
