/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { OauthsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { oauthsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

// The initial state of the Overview container
export const initialState: OauthsState = {
  oauthsList: [],
  isLoadingOauths: false,
  filterOauths: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfOauthsTable: 0,
  isAllOauthsFetched: false,
  displayFilterOauthsModal: false,
}

const oauthsSlice = createSlice({
  name: 'oauths',
  initialState,
  reducers: {
    getOauthsList(state) {},
    setOauths(state, action: PayloadAction<OauthsState['oauthsList']>) {
      state.oauthsList = action.payload
    },
    setIsLoadingOauths(
      state,
      action: PayloadAction<OauthsState['isLoadingOauths']>,
    ) {
      state.isLoadingOauths = action.payload
    },
    setFilterOauths(state, action: PayloadAction<OauthsState['filterOauths']>) {
      state.filterOauths = action.payload
    },
    setLastPageOfOauthsTable(state, action: PayloadAction<number>) {
      state.lastPageOfOauthsTable = action.payload
    },
    setIsAllOauthsFetched(state, action: PayloadAction<boolean>) {
      state.isAllOauthsFetched = action.payload
    },
    setDisplayFilterOauthsModal(state, action: PayloadAction<boolean>) {
      state.displayFilterOauthsModal = action.payload
    },
  },
})

export const {
  actions: oauthsActions,
  reducer: oauthsReducer,
  name: sliceKey,
} = oauthsSlice

export const useOauthsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: oauthsReducer })
  useInjectSaga({ key: sliceKey, saga: oauthsSaga })
  return { oauthsActions: oauthsActions }
}
