import { Box, styled } from '@mui/material'
import { Modal } from 'app/components/modal'
import { useDispatch, useSelector } from 'react-redux'
import { adminsActions } from '../../slice'
import { adminsSelectors } from '../../selectors'
import { H3_BOLD, TEXT_LG_SEMIBOLD } from 'styles/globalTypography'
import { COLUMN_CENTER } from 'styles/globalStyles'
import DeleteIcon from '@mui/icons-material/Delete'
import { mediaQueries } from 'styles/mediaQueries'
import PrimaryButton from 'app/components/button/primary-button'

export const DeleteConfirmationModal = () => {
  const dispatch = useDispatch()
  const {
    role,
    admin,
    display: isOpen,
  } = useSelector(adminsSelectors.deleteConfirmationModal)
  const handleModalClose = () => {
    dispatch(
      adminsActions.setDeleteConfirmationModalData({
        display: false,
        role: null,
        admin: null,
      }),
    )
  }

  const handleConfirmClick = () => {
    dispatch(adminsActions.deleteRoleFromAdmin())
    handleModalClose()
  }

  return (
    <CModal open={isOpen} onClose={handleModalClose}>
      <ModalContainer>
        <DeleteIcon
          color="error"
          sx={{
            width: {
              sm: '45px',
              xs: '32px',
            },
            height: {
              sm: '45px',
              xs: '32px',
            },
          }}
        />
        <Title>{'Do you want to delete this route?'}</Title>
        <Body>{role?.code}</Body>
        <PrimaryButton onClick={handleConfirmClick}>Confirm</PrimaryButton>
      </ModalContainer>
    </CModal>
  )
}

const CModal = styled(Modal)`
  .MuiDialog-paperWidthSm {
    width: 608px;
    max-height: 921px;
    overflow: hidden;
  }

  .MuiDialog-paper {
    max-width: 608px;
    max-height: 921px;
    padding: 32px;
  }
`

const ModalContainer = styled(Box)`
  ${COLUMN_CENTER};
  gap: 40px;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;
  `}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;
  `}
`

const Title = styled('p')`
  ${H3_BOLD}
  margin: 0;
  ${mediaQueries.lessThan('sm')`
  font-size: 20px;
  `}
  ${mediaQueries.lessThan('xs')`
  font-size: 16px;`}
`

const Body = styled('p')`
  ${TEXT_LG_SEMIBOLD}
  margin: 0;
  ${mediaQueries.lessThan('sm')`
  font-size: 14px;
  `}
  ${mediaQueries.lessThan('xs')`
  font-size: 12px;`}
`
