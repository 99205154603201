import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getLocalStorageOptions } from 'app/constants'

export const VOUCHERS_TABLE_KEY = 'vouchersTable'

export const columnsForShowInVouchersTable: ColumnOfTable[] = [
  { label: 'Admin', id: 'admin' },
  { label: 'User CoreID', id: 'userCoreID' },
  { label: 'Voucher Name', id: 'voucherName' },
  { label: 'Status', id: 'status' },
  { label: 'Amount', id: 'amount' },
  { label: 'BctxID', id: 'bctxID' },
  { label: 'TxHash', id: 'txHash' },
  { label: 'Created At', id: 'createdAt', isDate: true },
  { label: 'Updated At', id: 'updatedAt', isDate: true },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getLocalStorageOptions({ tableKey: VOUCHERS_TABLE_KEY })

//FIXME:update this when we have more status
export const STATUS_OPTIONS_OF_VOUCHERS = ['SUCCESSFUL', 'IN_PROGRESS']
