import { useDispatch, useSelector } from 'react-redux'
import { adminsActions } from './slice'
import { adminsSelectors } from './selectors'
import { useEffect } from 'react'
import { PageLoading } from 'app/components/common/loading/pageLoading'
import { DeleteConfirmationModal } from './components/modals/delete-confirmation'
import { Admin } from './types'
import AdminCard from './components/card/admin-card'
import { rolesActions } from '../Roles/slice'
import { AddAdminModal } from './components/modals/add-admin'
import PrimaryButton from 'app/components/button/primary-button'
import {
  COLUMN_JUSTIFY_START__ALIGN_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
} from 'styles/globalStyles'
import { mediaQueries } from 'styles/mediaQueries'
import { styled } from '@mui/material'

interface Props {}

export function Admins(props: Props) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(adminsActions.fetchAdmins({}))
    dispatch(rolesActions.fetchRoles({}))
  }, [])

  const adminsList = useSelector(adminsSelectors.adminList)
  const isLoadingAdmins = useSelector(adminsSelectors.isLoadingAdmins)

  const handleAddClick = () => {
    dispatch(adminsActions.setAddAdminModalDisplay(true))
  }

  return (
    <>
      <DeleteConfirmationModal />
      <AddAdminModal />
      <Wrapper>
        {isLoadingAdmins ? (
          <PageLoading />
        ) : (
          <>
            <TitleWrapper>
              <TabTitle>Admins</TabTitle>
              <PrimaryButton onClick={handleAddClick}>
                Add New Admin
              </PrimaryButton>
            </TitleWrapper>
            <AdminsContainer>
              {adminsList?.map((admin: Admin, index) => {
                return <AdminCard key={index} admin={admin} />
                // return <div key={role.ID}>{role.Name}</div>
              })}
            </AdminsContainer>
          </>
        )}
      </Wrapper>
    </>
  )
}

export const Wrapper = styled('div')`
  ${COLUMN_JUSTIFY_START__ALIGN_CENTER}
  gap: 24px;
  ${mediaQueries.lessThan('sm')`
    gap: 16px;
    `}
`

export const TitleWrapper = styled('div')`
  width: 100%;
  ${ROW_ALIGN_CENTER__SPACE_B}
`

export const TabTitle = styled('h1')`
  ${mediaQueries.lessThan('md')`
    font-size: 28px;
    line-height: 32px;
    `}
  ${mediaQueries.lessThan('sm')`
    font-size: 24px;
    line-height: 28px;
    margin:10px 0;
    `}
`

export const AdminsContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  width: 100%;
  gap: 1rem;
  ${mediaQueries.lessThan('xs')`
   grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
   gap: 0.5rem;
  `}
`
