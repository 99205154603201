import { PhoneInvalidationsHeader } from './components/Header'
import { FilterPhoneInvalidationsModal } from './components/modals/filter'
import { PhoneInvalidationsTable } from './components/table'

interface Props {}

export function PhoneInvalidations(props: Props) {
  return (
    <>
      <PhoneInvalidationsHeader />
      <PhoneInvalidationsTable />
      <FilterPhoneInvalidationsModal />
    </>
  )
}
