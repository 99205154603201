import { DocumentRejectionsHeader } from './components/Header'
import { FilterDocumentRejectionsModal } from './components/modals/filter'
import { DocumentRejectionsTable } from './components/table'

interface Props {}

export function DocumentRejections(props: Props) {
  return (
    <>
      <DocumentRejectionsHeader />
      <DocumentRejectionsTable />
      <FilterDocumentRejectionsModal />
    </>
  )
}
