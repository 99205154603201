//FIXME: we must remove this folder and this is a templete

import { AssetsManagementHeader } from './components/Header'
import { DetailsAssetsManagementModal } from './components/modals/details'
import { FilterAssetsManagementModal } from './components/modals/filter'
import { AssetsManagementTable } from './components/table'

interface Props {}

export function AssetsManagement(props: Props) {
  return (
    <>
      <AssetsManagementHeader />
      <AssetsManagementTable />
      <FilterAssetsManagementModal />
      <DetailsAssetsManagementModal />
    </>
  )
}
